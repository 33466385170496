<script>
  import Modal from "sv-bootstrap-modal";
  import { fly } from "svelte/transition";
  import { getNotificationsContext } from "svelte-notifications";
  import { Icon } from "svelte-fontawesome";
  import {
    faTimesCircle,
    faCheckCircle,
  } from "@fortawesome/free-solid-svg-icons";
  import RangeSlider from "svelte-range-slider-pips";

  import { adjustCollateralModal, adjust, adjustCur } from "../../store";
  import { postAdjust } from "../../services/collateral";
  import isEmpty from "../../utils/validation/is-empty";
  import insertThousandSeparator from "../../utils/thousand_separator";

  const { addNotification } = getNotificationsContext();

  let data = {
    spot: "",
    collateral: "",
  };
  let sliderValue = [];
  let maxValue;
  let action;

  let dec;
  let modalState;

  adjustCollateralModal.subscribe((v) => {
    modalState = v;
  });
  adjustCur.subscribe((v) => {
    dec = Math.pow(10, v.dec);

    adjust.subscribe((v) => {
      data.spot = v.w0;
      data.collateral = v.w2;
      sliderValue[0] = v.w2 * dec;
      maxValue = v.w0 + v.w2;
    });
  });

  const onClose = () => {
    adjustCollateralModal.set(false);
  };

  const changeSlider = (e) => {
    const value = e.detail.value / dec;

    if (value >= $adjust.w2) {
      data.collateral = value;
      data.spot = maxValue - data.spot;
      action = `Add ${$adjustCur.symbol} ${
        data.collateral - $adjust.w2
      } to collateral`;
    } else {
      const releaseAmount = $adjust.w2 - value;

      if ($adjust.w2_spare >= 0) {
        if (releaseAmount >= parseFloat($adjust.w2_spare)) {
          data.collateral = $adjust.w2 - parseFloat($adjust.w2_spare);
          data.spot = maxValue - data.collateral;
          sliderValue[0] = data.collateral * dec;
          action = `Remove ${$adjustCur.symbol} ${parseFloat(
            $adjust.w2_spare
          )} from collateral`;
        } else {
          data.collateral = value;
          data.spot = maxValue - data.collateral;
          // sliderValue[0] = data.collateral;
          action = `Remove ${$adjustCur.symbol} ${releaseAmount} from collateral`;
        }
      } else {
        data.spot = $adjust.w0;
        data.collateral = maxValue - data.spot;
        sliderValue[0] = data.collateral * dec;
        action = "";
      }
    }
  };

  const setMax = () => {
    data.collateral = maxValue;
    data.spot = 0;
    sliderValue[0] = data.collateral * dec;
    action = `Add ${$adjustCur.symbol} ${$adjust.w0} to collateral`;
  };

  const setMin = () => {
    if ($adjust.w2_spare >= 0) {
      data.collateral = $adjust.w2 - parseFloat($adjust.w2_spare);
      data.spot = maxValue - data.collateral;
      sliderValue[0] = data.collateral * dec;
      action = `Remove ${$adjustCur.symbol} ${parseFloat(
        $adjust.w2_spare
      )} from collateral`;
    } else {
      data.collateral = $adjust.w2;
      data.spot = maxValue - data.collateral;
      sliderValue[0] = data.collateral * dec;
      action = "";
    }
  };

  const onInput = (e) => {
    let { value, name } = e.target;

    if (name === "collateral") {
      if ($adjust.w2_spare >= 0) {
        if (value >= $adjust.w2) {
          data.collateral = value;
          data.spot = maxValue - data.collateral;
          sliderValue[0] = data.collateral * dec;
          action = `Add ${$adjustCur.symbol} ${parseFloat(
            value - $adjust.w2
          )} to collateral`;
        } else {
          const releaseAmount = $adjust.w2 - value;

          if (releaseAmount <= parseFloat($adjust.w2_spare)) {
            data.collateral = value;
            data.spot = maxValue - data.collateral;
            sliderValue[0] = data.collateral * dec;
            action = `Remove ${$adjustCur.symbol} ${releaseAmount} from collateral`;
          } else {
            data.collateral = $adjust.w2 - $adjust.w2_spare;
            data.spot = maxValue - data.collateral;
            sliderValue[0] = data.collateral * dec;
            action = `Remove ${$adjustCur.symbol} ${parseFloat(
              $adjust.w2_spare
            )} from collateral`;
          }
        }
      } else {
        if (value > $adjust.w2) {
          data.collateral = value;
          data.spot = maxValue - data.collateral;
          sliderValue[0] = data.collateral * dec;
          action = `Add ${$adjustCur.symbol} ${
            value - $adjust.w2
          } to collateral`;
        } else {
          data.collateral = $adjust.w2;
          data.spot = maxValue - data.collateral;
          sliderValue[0] = data.collateral * dec;
          action = "";
        }
      }
    } else if (name === "spot") {
      if ($adjust.w2_spare >= 0) {
        if ($adjust.w0 >= value) {
          data.spot = value;
          data.collateral = maxValue - value;
          sliderValue[0] = data.collateral * dec;
          action = `Add ${$adjustCur.symbol} ${
            $adjust.w0 - value
          } to collateral`;
        } else {
          const releaseAmount = value - $adjust.w0;

          if (releaseAmount <= parseFloat($adjust.w2_spare)) {
            data.collateral = $adjust.w2 - releaseAmount;
            data.spot = maxValue - data.collateral;
            sliderValue[0] = data.collateral * dec;
            action = `Remove ${$adjustCur.symbol} ${releaseAmount} from collateral`;
          } else {
            data.collateral = $adjust.w2 - $adjust.w2_spare;
            data.spot = maxValue - data.collateral;
            sliderValue[0] = data.collateral * dec;
            action = `Remove ${$adjustCur.symbol} ${parseFloat(
              $adjust.w2_spare
            )} from collateral`;
          }
        }
      } else {
        if ($adjust.w0 >= value) {
          data.spot = value;
          data.collateral = maxValue - data.spot;
          sliderValue[0] = data.collateral * dec;
          action = `Add ${$adjustCur.symbol} ${
            $adjust.w0 - value
          } to collateral`;
        } else {
          data.spot = $adjust.w0;
          data.collateral = maxValue - data.spot;
          sliderValue[0] = data.collateral * dec;
          action = "";
        }
      }
    }
  };

  const adjustCollateral = async () => {
    const amount = $adjust.w0 - data.spot;

    const res = await postAdjust($adjustCur.cur_id, amount);

    if (res === 0) {
      if (amount >= 0)
        addNotification({
          text: `Added ${$adjustCur.symbol} ${amount} to collateral`,
          position: "top-right",
          type: "success",
          removeAfter: 4000,
        });
      else
        addNotification({
          text: `Removed ${$adjustCur.symbol} ${Math.abs(
            amount
          )} from collateral`,
          position: "top-right",
          type: "success",
          removeAfter: 4000,
        });
    } else {
      if (!isEmpty(res.error_text))
        addNotification({
          text: res.error_text,
          position: "top-right",
          type: "danger",
          removeAfter: 4000,
        });
    }
  };

  $: if (!modalState) {
    data = {
      spot: "",
      collateral: "",
    };
    sliderValue = [];
    maxValue = 0;
    action = "";
  }
</script>

<Modal
  bind:open={$adjustCollateralModal}
  dialogClasses="modal-dialog-centered modal-lg"
>
  <div class="modal-header">
    <div />
    <div class="modal-title cl-primary">
      <span class="fs-5 me-1">Adjust</span>
      <img
        src={`../../vendor/image/${$adjustCur.icon_image}`}
        alt="asset"
        class="asset-icon me-1"
      />
      <span class="fs-5 me-1">{$adjustCur.symbol}</span>
      <span class="fs-5">Collateral</span>
    </div>
    <button type="button" class="btn-close" on:click={onClose} />
  </div>

  <div class="modal-body text-start">
    <form
      on:submit|preventDefault={adjustCollateral}
      in:fly={{ duration: 500, x: -300 }}
      novalidate
    >
      <div class="row mx-0 d-flex align-items-center">
        <div class="col-sm-6 my-2">
          <div
            class="d-flex justify-content-center align-items-center flex-column"
          >
            <span class="cl-dark-blue mb-3">Collateral</span>
            <div class="d-flex align-items-center">
              <span class="cl-dark-blue me-2">{$adjustCur.symbol}</span>
              <input
                type="number"
                class="form-control primary-input"
                name="collateral"
                id="collateral"
                value={data.collateral}
                on:input={onInput}
              />
              <div class="d-flex align-items-center flex-column ms-2">
                <span class="min-max" on:click={setMax}>MAX</span>
                <span class="min-max" on:click={setMin}>MIN</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 my-2">
          <div
            class="d-flex justify-content-center align-items-center flex-column"
          >
            <span class="cl-dark-blue mb-3">Spot Wallet</span>
            <div class="d-flex align-items-center">
              <span class="cl-dark-blue me-2">{$adjustCur.symbol}</span>
              <input
                type="number"
                class="form-control primary-input"
                name="spot"
                id="spot"
                value={data.spot}
                on:input={onInput}
              />
            </div>
          </div>
        </div>
      </div>

      {#if $adjust.w2_spare >= 0}
        <p class="cl-dark-blue text-center my-3">
          {$adjustCur.symbol}
          {insertThousandSeparator($adjust.w2_spare)} can be realeased.
        </p>
      {:else}
        <p class="cl-dark-blue text-center my-3">
          Add {$adjustCur.symbol}
          {insertThousandSeparator(Math.abs($adjust.w2_spare))} to restore LTV.
        </p>
      {/if}

      <div class="mt-3">
        <RangeSlider
          bind:values={sliderValue}
          min={0}
          max={maxValue * dec}
          step={1}
          range="min"
          on:change={changeSlider}
          on:start={changeSlider}
          on:stop={changeSlider}
        />
      </div>

      {#if !isEmpty(action)}
        <p class="cl-dark-blue text-center">
          {action}
        </p>
      {/if}

      <div class="d-flex justify-content-center mt-3">
        <!-- <button type="button" class="btn-orange" on:click={onClose}>
          <Icon icon={faTimesCircle} />
          <span class="ms-2">Cancel</span>
        </button> -->
        <button type="submit" class="btn-blue ms-3">
          <Icon icon={faCheckCircle} />
          <span class="ms-2">Transfer</span>
        </button>
      </div>
    </form>
  </div>
</Modal>

<style lang="scss">
  @import "../../vars.scss";

  :root {
    --range-slider: #d7dada; /* slider main background color */
    --range-handle-inactive: #ca9bc6; /* inactive handle color */
    --range-handle: #ca9bc6; /* non-focussed handle color */
    --range-handle-focus: #b471ae; /* focussed handle color */
    --range-handle-border: var(--range-handle);
    --range-range-inactive: var(
      --range-handle-inactive
    ); /* inactive range bar background color */
    --range-range: var(
      --range-handle-focus
    ); /* active range bar background color */
    --range-float-inactive: var(
      --range-handle-inactive
    ); /* inactive floating label background color */
    --range-float: var(
      --range-handle-focus
    ); /* floating label background color */
    --range-float-text: white; /* text color on floating label */
  }

  .min-max {
    color: $dark-blue;
    &:hover {
      cursor: pointer;
      color: $primary;
    }
  }

  .asset-icon {
    width: 1.8rem;
  }
</style>
