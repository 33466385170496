<script>
  import { Router, Link } from "svelte-navigator";
  import { fade, fly } from "svelte/transition";
  import { Icon } from "svelte-fontawesome";
  import {
    faBars,
    faUser,
    faSignInAlt,
    faUserTie,
    faSignOutAlt,
    faHome,
    faLandmarkDome,
    faHistory,
  } from "@fortawesome/free-solid-svg-icons";
  import Popover from "svelte-easy-popover";

  import { isAuth, errors } from "../../store";
  import { logout } from "../../services/auth";

  let innerWidth;
  let bottomNavbarDropdown;
  let topNavbarDropdown;

  const eraseErrMessage = () => {
    errors.set({});
  };

  const signout = async () => {
    isAuth.set(false);
    errors.set({});
    await logout();
  };
</script>

<svelte:window bind:innerWidth />
<Router>
  <nav
    class="navbar navbar-expand-md py-2 px-4 justify-content-between main-header"
    transition:fly={{ duration: 250, y: -80 }}
  >
    <Link class="navbar-brand " to="/">
      <img
        src="../../vendor/image/nav_logo.png"
        alt="nav-logo"
        class="nav-logo"
      />
    </Link>

    {#if innerWidth > 768}
      <div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <Icon icon={faBars} class="cl-primary" />
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li
              class="nav-item header-item"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              on:click={eraseErrMessage}
            >
              <Link class="nav-link" to="/">
                <span>Dashboard</span>
              </Link>
            </li>
            <li
              class="nav-item header-item"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              on:click={eraseErrMessage}
            >
              <Link class="nav-link" to="/collateral">
                <span>Collateral</span>
              </Link>
            </li>
            <li
              class="nav-item header-item"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              on:click={eraseErrMessage}
            >
              <Link class="nav-link" to="/history">
                <span>History</span>
              </Link>
            </li>

            {#if $isAuth}
              <li class="nav-item header-item">
                <div class="nav-link" bind:this={topNavbarDropdown}>
                  <Icon icon={faUser} class="cl-primary" />
                </div>
              </li>
              <!-- {#if innerWidth > 768}
              <div class="btn-group">
                  <li
                    class="nav-item header-item dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div class="nav-link">
                      <Icon icon={faUser} class="cl-primary" />
                    </div>
                  </li>

                  <ul class="dropdown-menu dropdown-menu-end">
                    <li on:click={eraseErrMessage}>
                      <Link
                        class="dropdown-item cl-primary"
                        to="/profile#account"
                      >
                        <Icon icon={faUserTie} class="cl-primary" />
                        <span>Profile</span>
                      </Link>
                    </li>
                    <li on:click={signout}>
                      <Link class="dropdown-item cl-primary" to="/signin">
                        <Icon icon={faSignOutAlt} class="cl-primary" />
                        <span>Sign Out</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              {:else}
                <li
                  class="nav-item header-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                  on:click={eraseErrMessage}
                >
                  <Link class="nav-link" to="/profile">
                    <Icon icon={faUserTie} class="cl-primary" />
                    <span>Profile</span>
                  </Link>
                </li>
                <li
                  class="nav-item header-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                  on:click={signout}
                >
                  <div class="nav-link">
                    <Icon icon={faSignOutAlt} class="cl-primary" />
                    <span>Sign Out</span>
                  </div>
                </li>
              {/if} -->
            {:else}
              <li
                class="nav-item header-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                on:click={eraseErrMessage}
              >
                <Link class="nav-link" to="/signin">
                  <Icon icon={faSignInAlt} class="cl-primary" />
                  <span>Sign in</span>
                </Link>
              </li>
            {/if}
          </ul>
        </div>
      </div>
    {/if}
  </nav>

  {#if innerWidth <= 768 && $isAuth}
    <nav
      class="navbar navbar-expand-md py-2 px-4 bottom-navbar"
      transition:fly={{ duration: 250, y: 50 }}
    >
      <ul class="navbar-nav flex-row justify-content-between full-width">
        <li class="nav-item bottom-nav-item" on:click={eraseErrMessage}>
          <Link class="nav-link" to="/">
            <Icon icon={faHome} class="fs-4" />
          </Link>
        </li>
        <li class="nav-item bottom-nav-item" on:click={eraseErrMessage}>
          <Link class="nav-link" to="/collateral">
            <Icon icon={faLandmarkDome} class="fs-4" />
          </Link>
        </li>
        <li class="nav-item bottom-nav-item" on:click={eraseErrMessage}>
          <Link class="nav-link" to="/history">
            <Icon icon={faHistory} class="fs-4" />
          </Link>
        </li>
        {#if $isAuth}
          <div
            class="nav-link nav-item bottom-nav-item"
            bind:this={bottomNavbarDropdown}
          >
            <Icon icon={faUser} class="fs-4" />
          </div>
        {:else}
          <li class="nav-item bottom-nav-item" on:click={eraseErrMessage}>
            <Link class="nav-link" to="/signin">
              <Icon icon={faSignInAlt} class="fs-4" />
            </Link>
          </li>
        {/if}
      </ul>
    </nav>
  {/if}

  <Popover
    triggerEvents={["focus", "hover"]}
    referenceElement={bottomNavbarDropdown}
    placement="top-end"
    spaceAway={15}
    closeOnClickAway
    closeOnEscape
  >
    <div class="popover-contents" transition:fade={{ duration: 250 }}>
      <Link to="/profile#account">
        <div on:click={eraseErrMessage} class="popover-item">
          <Icon icon={faUserTie} />
          <span>Profile</span>
        </div>
      </Link>

      <div class="popover-item" on:click={signout}>
        <Icon icon={faSignOutAlt} />
        <span>Sign Out</span>
      </div>
    </div>
  </Popover>

  <Popover
    triggerEvents={["focus", "hover"]}
    referenceElement={topNavbarDropdown}
    placement="bottom-end"
    spaceAway={25}
    closeOnClickAway
    closeOnEscape
  >
    <div class="popover-contents" transition:fade={{ duration: 250 }}>
      <Link to="/profile#account">
        <div on:click={eraseErrMessage} class="popover-item">
          <Icon icon={faUserTie} />
          <span>Profile</span>
        </div>
      </Link>

      <div class="popover-item" on:click={signout}>
        <Icon icon={faSignOutAlt} />
        <span>Sign Out</span>
      </div>
    </div>
  </Popover>
</Router>

<style lang="scss">
  @import "../../vars.scss";

  .main-header {
    // overflow: hidden;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    background-color: $navbar-bg;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 24px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    @media screen and (max-width: 768px) {
      position: relative;
    }
  }

  .bottom-navbar {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 10;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    background-color: $bottom-navbar-bg;
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.2),
      0 -4px 24px 0 rgba(0, 0, 0, 0.19);
  }

  .nav-logo {
    height: 3rem;
    @media screen and (max-width: 576px) {
      height: 2.2rem;
    }
  }

  .nav-item .nav-link span {
    color: $primary;
  }

  .nav-item:hover .nav-link span {
    color: $primary;
  }

  .bottom-nav-item {
    cursor: pointer;
    color: $primary;
    transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    &:hover {
      color: $primary-lighter !important;
    }
  }

  .header-item {
    cursor: pointer;
    margin: 0 0.3rem;
    height: 2.5rem;
    border-bottom: 0 solid $primary;
    transition: border-bottom 0.2s ease-in-out;
    -webkit-transition: border-bottom 0.2s ease-in-out;
  }

  .header-item:hover {
    border-bottom: 1px solid $primary;
  }

  // .dropdown-toggle::after {
  //   content: none;
  // }

  // .dropdown-menu {
  //   min-width: 9rem;
  // }

  .navbar-toggler {
    color: transparent;
  }

  .popover-contents {
    background-color: white;
    border-radius: 0.5rem;
    padding: 0.2rem;
    width: 150px;
    text-align: start;
    color: $primary;
    z-index: 11;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  }

  .popover-item {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 0.5rem;
    color: $primary;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    &:hover {
      background-color: $primary-lighter;
      color: white;
    }
  }
</style>
