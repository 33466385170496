<script>
  import { fly, fade } from "svelte/transition";
  import { Icon } from "svelte-fontawesome";
  import { faInfoCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
  import Popover from "svelte-easy-popover";

  import { cursList, bank_list } from "../../store";

  export let account;
  export let onDeleteAccount;

  let referenceElement;
  let referenceInfo;

  let state = account.state.charAt(0).toUpperCase() + account.state.slice(1);

  let currency = $cursList.find((item) => item.cur_id == account.cur_id);
  let bank = $bank_list.find((item) => item.bank_id == account.bank_id);

  const onDelete = async () => {
    await onDeleteAccount(account.id);
  };
</script>

<div class="row mx-0 py-1 my-1 account-item" in:fly={{ duration: 500, y: 500 }}>
  <div class="col-2 d-flex justify-content-start align-items-center">
    <div class="d-flex justify-content-center align-items-center">
      <img
        src={`../../vendor/image/${bank.icon_image}`}
        alt="bank"
        class="asset-icon me-2"
      />
      <span>{bank.name}</span>
    </div>
  </div>
  <div class="col-3 d-flex justify-content-center align-items-center">
    {account.account_number}
  </div>
  <div class="col-2 d-flex justify-content-center align-items-center">
    <div class="d-flex justify-content-center align-items-center">
      <img
        src={`../../vendor/image/${currency.icon_image}`}
        alt="asset"
        class="asset-icon me-2"
      />
      <span>{currency.symbol}</span>
    </div>
  </div>
  <div class="col-3 d-flex justify-content-center align-items-center">
    <div>
      <span>{new Date(account.created).toLocaleDateString()}</span>
      <span>{new Date(account.created).toLocaleTimeString("it-IT")}</span>
    </div>
  </div>
  <div class="col-2 d-flex justify-content-center align-items-center">
    {#if account.state !== "confirmed"}
      <div class="d-flex info-icon me-2" bind:this={referenceInfo}>
        <Icon icon={faInfoCircle} />
      </div>
    {/if}
    <button
      type="button"
      class="btn btn-danger btn-sm rounded-circle"
      bind:this={referenceElement}
    >
      <Icon icon={faTrashAlt} />
    </button>
    <Popover
      triggerEvents={["focus", "click"]}
      {referenceElement}
      placement="left"
      spaceAway={10}
      closeOnClickAway
    >
      <div class="popover-contents" transition:fade={{ duration: 250 }}>
        <p>Do you really delete this?</p>
        <div class="d-flex justify-content-end align-items-center">
          <button
            type="button"
            class="btn btn-sm btn-light me-2"
            on:click={onDelete}>Yes</button
          >
          <button type="button" class="btn btn-sm btn-light">No</button>
        </div>
      </div>
    </Popover>

    <Popover
      triggerEvents={["focus", "hover"]}
      referenceElement={referenceInfo}
      placement="left"
      spaceAway={7}
    >
      <div class="info-text" transition:fade={{ duration: 250 }}>
        {state}
      </div>
    </Popover>
  </div>
</div>

<style lang="scss">
  @import "../../vars.scss";

  .asset-icon {
    width: 1.8rem;
  }

  .account-item {
    border-radius: 0.2rem;
  }

  .account-item:hover {
    background-color: $trans-item-hover;
  }

  .info-icon {
    font-size: 1.25rem;
    transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
  }

  .info-icon:hover {
    cursor: pointer;
    color: $info-icon-hover;
  }

  .popover-contents {
    background-color: $popover-bg;
    border-radius: 0.5rem;
    padding: 0.5rem;
    // color: $dark-blue;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  }

  .info-text {
    background-color: $info-icon-hover;
    color: white;
    padding: 0.25rem;
    border-radius: 0.2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  }
</style>
