import { page_size, current_page, total_trans, bank_page_size, bank_current_page, total_banks, crypto_current_page, crypto_page_size, total_cryptos } from '../../store';

const isLastPage = (pageNum, id) => {
  let historyPageSize;
  let historyCurrentPage;
  let totalTrans;
  let bankPageSize;
  let bankCurrentPage;
  let totalBanks;
  let cryptoPageSize;
  let cryptoCurrentPage;
  let totalCryptos;

  if (id === 'history') {
    page_size.subscribe(v => {
      historyPageSize = v;
    })
    current_page.subscribe(v => {
      historyCurrentPage = v;
    })
    total_trans.subscribe(v => {
      totalTrans = v;
    })

    if (pageNum * historyPageSize >= totalTrans) return true;
    else return false;
  } else if (id = 'bank_account') {
    bank_page_size.subscribe(v => {
      bankPageSize = v;
    })
    bank_current_page.subscribe(v => {
      bankCurrentPage = v;
    })
    total_banks.subscribe(v => {
      totalBanks = v;
    })

    if (pageNum * bankPageSize >= totalBanks) return true;
    else false;
  } else if (id = 'crypto_account') {
    crypto_page_size.subscribe(v => {
      cryptoPageSize = v;
    })
    crypto_current_page.subscribe(v => {
      cryptoCurrentPage = v;
    })
    total_cryptos.subscribe(v => {
      totalCryptos = v;
    })

    if (pageNum * cryptoPageSize >= totalCryptos) return true;
    else return false;
  }
};

export default isLastPage;