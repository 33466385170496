const insertThousandSeparator = (value) => {
  let result = value;
  let strVal = value.toString().split(".");

  if (strVal[0].length > 3) {
    let overDec = strVal[0].split("");
    let count = 0;
    let temp = "";

    for (let i = overDec.length - 1; i >= 0; i--) {
      if (count < 2) {
        temp = overDec[i] + temp;
        count++;
      } else {
        if (i === 0) {
          temp = overDec[i] + temp;
          count = 0;

        } else {
          if (i === 1 && overDec[0] === "-") {
            temp = overDec[i] + temp;
            count = 0;
          } else {
            temp = "," + overDec[i] + temp;
            count = 0;
          }
        }
      }
    }

    if (strVal.length === 1) {
      result = temp;
    } else {
      result = temp + "." + strVal[1];
    }
  }

  return result;
};

export default insertThousandSeparator;