import api from '../utils/api';
import { fiat_deposit, crypto_deposit } from '../store';
import { logout } from './auth';

// Get fiat deposit
export const getFiatDeposit = async (cur_id) => {
  try {
    const res = await api.get(`/fiat/deposit/${cur_id}`);

    if (res.result === 0) {
      console.log(res)
      fiat_deposit.set(res);
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Get crypto deposit
export const getCryptoDeposit = async (cur_id) => {
  try {
    const res = await api.get(`/crypto/deposit/${cur_id}`);

    if (res.result === 0) {
      console.log(res)
      crypto_deposit.set(res);
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};