import isEmpty from './is-empty';
import { asset_amounts } from '../../store';

const withdrawValidation = (data) => {
  let errorMsgs = {};

  let currentAsset;

  if (isEmpty(data.asset)) {
    errorMsgs.asset = 'require';
  } else {
    asset_amounts.subscribe(v => {
      currentAsset = v.filter(
        (item) => item.cur_id == data.asset.value
      );
    })

    if (isEmpty(data.amount)) {
      errorMsgs.amount = 'require';
    } else if (data.amount > currentAsset[0].amount - currentAsset[0].fee) {
      errorMsgs.amount = 'Over maximum amount';
    } else if (data.amount < currentAsset[0].min_withdraw) {
      errorMsgs.amount = 'Under minimum withdraw amount';
    }
  }

  if (isEmpty(data.account)) {
    errorMsgs.account = 'require';
  }

  return {
    errorMsgs,
    isValid: isEmpty(errorMsgs)
  };
};

export default withdrawValidation;