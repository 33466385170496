<script>
  import { fly } from "svelte/transition";
  import { getNotificationsContext } from "svelte-notifications";

  import { errors } from "../../store";
  import changePwValidation from "../../utils/validation/changePwValidation";
  import { changePw } from "../../services/auth";
  import isEmpty from "../../utils/validation/is-empty";

  const { addNotification } = getNotificationsContext();

  let newPw = {
    currentPw: "",
    password: "",
    password2: "",
  };

  const onInput = (e) => {
    errors.set({
      ...$errors,
      [e.target.name]: "",
    });
  };

  const changePassword = async () => {
    const { errorMsgs, isValid } = changePwValidation(newPw);

    if (isValid) {
      const newPassword = {
        current: newPw.currentPw,
        new: newPw.password,
      };

      const res = await changePw(newPassword);

      if (res === 0) {
        newPw = {
          currentPw: "",
          password: "",
          password2: "",
        };

        addNotification({
          text: "Successfully changed password",
          position: "top-right",
          type: "success",
          removeAfter: 4000,
        });
      } else {
        if (!isEmpty(res.error_text))
          addNotification({
            text: res.error_text,
            position: "top-right",
            type: "danger",
            removeAfter: 4000,
          });
      }
    } else {
      errors.set(errorMsgs);
    }
  };
</script>

<div
  class="d-flex justify-content-center align-items-center"
  in:fly={{ duration: 500, x: 300 }}
>
  <div class="changepw-card text-start">
    <p class="fs-2 text-center cl-primary fw-bold">Change Your Password</p>

    <form on:submit|preventDefault={changePassword} novalidate>
      <div class="form-group">
        <label for="currentPw" class="cl-primary">Current Password</label>
        <input
          type="password"
          class={`form-control mt-2 second-input ${
            $errors.currentPw ? "is-invalid" : null
          }`}
          name="currentPw"
          id="currentPw"
          bind:value={newPw.currentPw}
          on:input={onInput}
        />
        {#if $errors.currentPw}
          <div class="text-danger">{$errors.currentPw}</div>
        {/if}
      </div>
      <div class="form-group mt-3">
        <label for="password" class="cl-primary">New Password</label>
        <input
          type="password"
          class={`form-control mt-2 second-input ${
            $errors.password ? "is-invalid" : null
          }`}
          name="password"
          id="password"
          bind:value={newPw.password}
          on:input={onInput}
        />
        {#if $errors.password}
          <div class="text-danger">{$errors.password}</div>
        {/if}
      </div>
      <div class="form-group mt-3">
        <label for="password2" class="cl-primary">Confirm Password</label>
        <input
          type="password"
          class={`form-control mt-2 second-input ${
            $errors.password2 ? "is-invalid" : null
          }`}
          name="password2"
          id="password2"
          bind:value={newPw.password2}
          on:input={onInput}
        />
        {#if $errors.password2}
          <div class="text-danger">{$errors.password2}</div>
        {/if}
      </div>
      <button type="submit" class="btn-main mt-3 full-width"
        >Change Password</button
      >
    </form>
  </div>
</div>

<style lang="scss">
  @import "../../vars.scss";

  .changepw-card {
    position: relative;
    background-color: $card-bg;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.3rem;
    padding: 0.8rem;
    width: fit-content;
  }
</style>
