<script>
  import Modal from "sv-bootstrap-modal";
  import { getNotificationsContext } from "svelte-notifications";
  import { Icon } from "svelte-fontawesome";
  import {
    faHandHoldingDollar,
    faTimesCircle,
  } from "@fortawesome/free-solid-svg-icons";

  import {
    borrowModal,
    loan,
    errors,
    cursList,
    collaterals,
    userInfo,
  } from "../../store";
  import isEmpty from "../../utils/validation/is-empty";
  import { loanBorrow } from "../../services/borrow";
  import { getBalances } from "../../services/finance";
  import insertThousandSeparator from "../../utils/thousand_separator";

  const { addNotification } = getNotificationsContext();

  let borrowAmount;
  let max_loan;
  let options = [];
  let last = [];
  let disbledBorrow = false;
  let loanAmount;

  let local_currency = $cursList.find(
    (item) => item.cur_id == $userInfo.local_cur_id
  );

  loan.subscribe((v) => {
    max_loan = Math.abs(v.max_loan);
  });

  const onClose = () => {
    borrowModal.set(false);
  };

  const onChangeAmount = (e) => {
    options = [];
    last = [];

    errors.set({
      ...$errors,
      borrowAmount: "",
    });

    const amount = e.target.value;

    if (amount > max_loan) {
      disbledBorrow = true;
      errors.set({
        ...$errors,
        borrowAmount: "Over maximum amount of loan",
      });

      let ltv = $loan.ltv;
      let excess = amount - max_loan;
      let assets = [];

      $collaterals.forEach((item) => {
        let currency = $cursList.find(
          (cur) => cur.cur_id == item[0].substr(1, 1)
        );

        if (currency.symbol !== "ZAR") assets.push(currency);
      });

      assets.forEach((asset) => {
        let collateral = $collaterals.find(
          (item) => item[0].substr(1, 1) == asset.cur_id
        );
        let amount = excess / collateral[1].price[0] / ltv;

        options.push({
          asset: asset,
          amount: amount,
        });
      });

      last = options.pop();
    }
  };

  const inputMaxAmount = () => {
    errors.set({
      ...$errors,
      borrowAmount: "",
    });

    borrowAmount = max_loan.toFixed(local_currency.dec);
    disbledBorrow = false;
    options = [];
    last = [];
  };

  const onBorrow = async () => {
    const data = {
      cur_id: 1,
      amount: borrowAmount,
    };

    const res = await loanBorrow(data);

    if (res === 0) {
      borrowModal.set(false);

      addNotification({
        text: "Successfully borrowed loan",
        position: "top-right",
        type: "success",
        removeAfter: 4000,
      });

      await getBalances();
    } else {
      if (!isEmpty(res.error_text))
        addNotification({
          text: res.error_text,
          position: "top-right",
          type: "danger",
          removeAfter: 4000,
        });
    }
  };

  $: if (!isEmpty($collaterals)) {
    const zar = $collaterals.find((item) => item[0] === "c1");

    if (isEmpty(zar[1].w1)) {
      loanAmount = 0;
    } else {
      loanAmount = Math.abs(zar[1].w1.s2);
    }
  }
  $: if (!$borrowModal) {
    errors.set({});
    borrowAmount = "";
    disbledBorrow = false;
    options = [];
    last = [];
  }
</script>

<Modal bind:open={$borrowModal} dialogClasses="modal-dialog-centered">
  <div class="modal-header">
    <h5 class="modal-title cl-primary">Borrow</h5>
    <button type="button" class="btn-close" on:click={onClose} />
  </div>

  <div class="modal-body text-start">
    <div class="d-flex mb-2">
      <span class="cl-primary me-2">Existing Loan:</span>
      <span class="cl-dark-blue"
        >{insertThousandSeparator(loanAmount.toFixed(local_currency.dec))}</span
      >
    </div>
    <form on:submit|preventDefault={onBorrow} novalidate>
      <div class="form-group">
        <label for="borrowAmount" class="cl-primary mb-2"
          >I want to borrow:</label
        >
        <div class="input-group">
          <input
            type="number"
            class={`form-control second-input ${
              $errors.borrowAmount ? "is-invalid" : null
            }`}
            name="borrowAmount"
            id="borrowAmount"
            bind:value={borrowAmount}
            on:input={onChangeAmount}
            aria-describedby="max-suffix"
          />
          <span
            class="input-group-text"
            id="max-suffix"
            on:click={inputMaxAmount}>Max</span
          >
        </div>
        {#if $errors.borrowAmount}
          <div class="text-danger">{$errors.borrowAmount}</div>
        {/if}
      </div>

      <p class="cl-dark-blue my-2">
        You can borrow <span class="fw-bold"
          >ZAR {insertThousandSeparator(
            max_loan.toFixed(local_currency.dec)
          )}</span
        > without adding collateral.
      </p>

      {#if !isEmpty(options) || !isEmpty(last)}
        <div class="my-2">
          <p class="cl-dark-blue">
            You need to add more collateral to support this loan:
          </p>

          {#each options as option}
            <div class="d-flex justify-content-center align-items-center my-2">
              <img
                src={`../../vendor/image/${option.asset.icon_image}`}
                alt="asset"
                class="asset-icon me-2"
              />
              <span class="cl-dark-blue">{option.asset.symbol}</span>
              <span class="ms-2 cl-dark-blue"
                >{insertThousandSeparator(
                  option.amount.toFixed(option.asset.dec)
                )}</span
              >
            </div>
            <p class="cl-dark-blue text-center my-0">or</p>
          {/each}

          {#if !isEmpty(last)}
            <div class="d-flex justify-content-center align-items-center my-2">
              <img
                src={`../../vendor/image/${last.asset.icon_image}`}
                alt="asset"
                class="asset-icon me-2"
              />
              <span class="cl-dark-blue">{last.asset.symbol}</span>
              <span class="ms-2 cl-dark-blue"
                >{new Intl.NumberFormat().format(last.amount)}</span
              >
            </div>
          {/if}
        </div>
      {/if}

      <div class="d-flex justify-content-center mt-3">
        <!-- <button type="button" class="btn-orange" on:click={onClose}>
          <Icon icon={faTimesCircle} />
          <span class="ms-2">Cancel</span>
        </button> -->
        <button type="submit" class="btn-main ms-3" disabled={disbledBorrow}>
          <Icon icon={faHandHoldingDollar} />
          <span class="ms-2">Borrow</span>
        </button>
      </div>
    </form>
  </div>
</Modal>

<style lang="scss">
  @import "../../vars.scss";

  #max-suffix {
    background-color: $max-suffix-bg;
    color: $primary-dark;
    transition: background-color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out;
  }

  #max-suffix:hover {
    cursor: pointer;
    background-color: $max-suffix-bg-hover;
  }

  .asset-icon {
    width: 1.8rem;
  }
</style>
