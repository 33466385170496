<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";
  import { fly } from "svelte/transition";

  import { login } from "../../services/auth";
  import { errors, resetPwModal, isAuth } from "../../store";
  import signinValidation from "../../utils/validation/signinValidation";

  import ResetPwModal from "../../components/auth/ResetPwModal.svelte";

  let userData = {
    email: "",
    password: "",
  };
  let errMessages;

  errors.subscribe((value) => {
    errMessages = value;
  });

  onMount(() => {
    if (localStorage.auth_token) {
      localStorage.removeItem("auth_token");
    }

    if (localStorage.token) {
      navigate("/", { replace: true });
    }
  });

  const navigateSingup = () => {
    errors.set({});
    navigate("/signup", { replace: true });
  };

  const onInput = (e) => {
    errors.set({
      ...$errors,
      [e.target.name]: "",
    });
  };

  const resetPw = async () => {
    resetPwModal.set(true);
  };

  const signin = async () => {
    const { errorMsgs, isValid } = await signinValidation(userData);

    if (isValid) {
      userData.device_uuid = localStorage.deviceID;

      await login(userData);
    } else errors.set(errorMsgs);
  };
</script>

<ResetPwModal />
<div
  class="d-flex justify-content-center align-items-center"
  in:fly={{ duration: 500, x: 300 }}
>
  <div class="fit-content-width text-start">
    <p class="fs-2 cl-primary fw-bold">Coin Stash Account Login</p>

    <form on:submit|preventDefault={signin} novalidate>
      <div class="form-group">
        <label for="email" class="cl-primary">Email</label>
        <input
          type="email"
          class={`form-control mt-2 primary-input ${
            errMessages.email ? "is-invalid" : null
          }`}
          name="email"
          id="email"
          bind:value={userData.email}
          on:input={onInput}
        />
        {#if errMessages.email}
          <div class="text-danger">{errMessages.email}</div>
        {/if}
      </div>

      <div class="form-group mt-3">
        <label for="password" class="cl-primary">Password</label>
        <input
          type="password"
          class={`form-control mt-2 primary-input ${
            errMessages.password ? "is-invalid" : null
          }`}
          name="password"
          id="password"
          bind:value={userData.password}
          on:input={onInput}
        />
        {#if errMessages.password}
          <div class="text-danger">{errMessages.password}</div>
        {/if}
      </div>

      <div class="mt-1 text-end cl-primary auth-link mb-0" on:click={resetPw}>
        Forgot password?
      </div>
      <button type="submit" class="btn-main mt-3 full-width">Sign In</button>
    </form>

    <hr class="mt-4" />

    <div>
      <span class="cl-dark-blue">Not have an account yet?</span>
      <span class="cl-primary auth-link" on:click={navigateSingup}>
        Create account
      </span>
    </div>
  </div>
</div>

<style lang="scss">
  .auth-link {
    text-decoration: none !important;
    color: #964f90 !important;
  }

  .auth-link:hover {
    color: #964f90 !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
</style>
