<script>
  import { Icon } from "svelte-fontawesome";
  import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

  export let disabledNext;
  export let nextPage;

  const setNextPage = () => {
    if (!disabledNext) nextPage();
  };
</script>

<div
  class={`${disabledNext ? "disable-btn" : "next-btn"}`}
  on:click={setNextPage}
>
  <Icon icon={faChevronRight} />
</div>

<style lang="scss">
  @import "../../vars.scss";

  .disable-btn {
    margin-top: 2px;
    color: $disabled;
    padding: 0.5rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }

  .next-btn {
    margin-top: 2px;
    color: $dark-blue;
    padding: 0.5rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }

  .next-btn:hover {
    cursor: pointer;
    color: $primary;
    background-color: $trans-item-hover;
  }
</style>
