<script>
  import { fade } from "svelte/transition";
  import { Icon } from "svelte-fontawesome";
  import {
    faHandHoldingDollar,
    faCheckToSlot,
    faLandmarkDome,
  } from "@fortawesome/free-solid-svg-icons";
  import { Link } from "svelte-navigator";
  import { getNotificationsContext } from "svelte-notifications";

  import {
    loan,
    cursList,
    settleModal,
    borrowModal,
    userInfo,
    collaterals,
  } from "../../store";
  import insertThousandSeparator from "../../utils/thousand_separator";

  import LTVRatio from "../ltv-chart/LTVRatio.svelte";

  import isEmpty from "../../utils/validation/is-empty";

  const { addNotification } = getNotificationsContext();

  let loans = [];
  let collateral = [];

  let loanString;
  let collateralString;

  let zar = $cursList.find((item) => item.symbol === "ZAR");
  let local_currency = $cursList.find(
    (item) => item.cur_id == $userInfo.local_cur_id
  );

  if (!isEmpty($loan.loans)) {
    Object.entries($loan.loans).forEach((item) => {
      let currency = $cursList.find((i) => i.cur_id == item[0].substr(1, 1));
      loans.push({
        symbol: currency.symbol,
        amount: insertThousandSeparator(
          Math.abs(item[1]).toFixed(local_currency.dec)
        ),
      });
    });

    loanString = loans[0].symbol + " " + loans[0].amount;

    loans.forEach((item, index) => {
      if (index !== 0)
        loanString = loanString.concat(" + ", item.symbol, " ", item.amount);
    });

    if (
      (Object.keys($loan.loans).length === 1 && isEmpty($loan.loans.c1)) ||
      Object.keys($loan.loans).length !== 1
    ) {
      loanString = loanString.concat(
        " = ZAR ",
        insertThousandSeparator(
          Math.abs($loan.loan_eq).toFixed(local_currency.dec)
        )
      );
    }
  }

  if (!isEmpty($loan.collateral)) {
    Object.entries($loan.collateral).forEach((item) => {
      let currency = $cursList.find((i) => i.cur_id == item[0].substr(1, 1));
      collateral.push({
        symbol: currency.symbol,
        amount: insertThousandSeparator(
          Math.abs(item[1]).toFixed(currency.dec)
        ),
      });
    });

    collateralString = collateral[0].symbol + " " + collateral[0].amount;

    collateral.forEach((item, index) => {
      if (index !== 0)
        collateralString = collateralString.concat(
          " + ",
          item.symbol,
          " ",
          item.amount
        );
    });

    collateralString = collateralString.concat(
      " = ZAR ",
      insertThousandSeparator($loan.collateral_eq.toFixed(local_currency.dec))
    );
  }

  const openSettle = () => {
    if (isEmpty($collaterals)) {
      addNotification({
        text: "You don't have any balance",
        position: "top-right",
        type: "warning",
        removeAfter: 4000,
      });
    } else {
      const zar = $collaterals.find((item) => item[0] === "c1");

      if (isEmpty(zar[1].w1)) {
        addNotification({
          text: "You don't have any loan",
          position: "top-right",
          type: "warning",
          removeAfter: 4000,
        });
      } else if (zar[1].w1.s2 === 0) {
        addNotification({
          text: "You don't have any loan",
          position: "top-right",
          type: "warning",
          removeAfter: 4000,
        });
      } else {
        settleModal.set(true);
      }
    }
  };

  const openBorrow = () => {
    if (isEmpty($collaterals)) {
      addNotification({
        text: "You don't have any balance",
        position: "top-right",
        type: "warning",
        removeAfter: 4000,
      });
    } else {
      const zar = $collaterals.find((item) => item[0] === "c1");

      if (isEmpty(zar[1].w1)) {
        addNotification({
          text: "You don't have any loan",
          position: "top-right",
          type: "warning",
          removeAfter: 4000,
        });
      } else if (zar[1].w1.s2 === 0) {
        addNotification({
          text: "You don't have any loan",
          position: "top-right",
          type: "warning",
          removeAfter: 4000,
        });
      } else {
        borrowModal.set(true);
      }
    }
  };
</script>

<div class="fit-content-width" in:fade={{ duration: 300 }}>
  <p class="text-start">
    <span class="fw-bold me-2 cl-dark-blue">Loan:</span>
    <span class="cl-dark-blue">{isEmpty(loanString) ? "-" : loanString}</span>
  </p>
  <p class="text-start">
    <span class="fw-bold me-2 cl-dark-blue">Collateral:</span>
    <span class="cl-dark-blue"
      >{isEmpty(collateralString) ? "-" : collateralString}</span
    >
  </p>
  <p class="text-start">
    <span class="fw-bold me-2 cl-dark-blue">Interest Rate:</span>
    <span class="cl-dark-blue">{zar.borrow_rate}% APY (calculated daily)</span>
  </p>

  {#if $loan.max_loan !== false}
    <p class="text-start cl-dark-blue">
      With this collateral, you can borrow: ZAR {insertThousandSeparator(
        $loan.max_loan.toFixed(local_currency.dec)
      )}
    </p>
  {/if}

  {#if $loan.ltv_shortage !== false}
    <p class="text-start cl-dark-blue">
      To restore your LTV ratio to 50% please add collateral of value: ZAR {insertThousandSeparator(
        $loan.ltv_shortage.toFixed(local_currency.dec)
      )}
    </p>
  {/if}

  <LTVRatio series={new Intl.NumberFormat().format($loan.ltv * 100)} />

  <div class="d-flex justify-content-center flex-wrap">
    <button type="button" class="btn-blue mx-1 my-1" on:click={openSettle}>
      <Icon icon={faCheckToSlot} />
      <span class="ms-2">Settle</span>
    </button>
    <button type="button" class="btn-main mx-1 my-1" on:click={openBorrow}>
      <Icon icon={faHandHoldingDollar} />
      <span class="ms-2">Borrow</span>
    </button>
    <Link to="/collateral">
      <button type="button" class="btn-orange mx-1 my-1">
        <Icon icon={faLandmarkDome} />
        <span class="ms-2">Adjust Collateral</span>
      </button>
    </Link>
  </div>
</div>
