import api from '../utils/api';
import { logout } from './auth';
import { isUploading, uploadType } from '../store';

// Upload file
export const uploadFile = async (file) => {
  try {
    isUploading.set(true);
    uploadType.set(file.type);

    const res = await api.post('/document', file);

    if (res.result === 0) {
      isUploading.set(false);
      return res.result;
    } else {
      console.log(res);
      isUploading.set(false);

      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};