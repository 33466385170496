import Validator from 'validator';
import isEmpty from './is-empty';

const resetEmailValidation = (data) => {
  let errorMsgs = {};

  data.email = !isEmpty(data.email) ? data.email.toString() : '';

  if (Validator.isEmpty(data.email)) {
    errorMsgs.email = 'require';
  }

  if (!Validator.isEmpty(data.email) && !Validator.isEmail(data.email)) {
    errorMsgs.email = 'Invalid email';
  }

  return {
    errorMsgs,
    isValid: isEmpty(errorMsgs)
  };
};

export default resetEmailValidation;