<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { getNotificationsContext } from "svelte-notifications";

  import { errors, isForgotPwTicketLoading } from "../../store";
  import resetPwValidation from "../../utils/validation/resetPwValidation";
  import isEmpty from "../../utils/validation/is-empty";
  import { resetPassword, getForgotPwTicket } from "../../services/auth";

  import DashboardSpinner from "../../components/loading/DashboardSpinner.svelte";

  const { addNotification } = getNotificationsContext();

  export let token;

  let newPw = {
    password: "",
    password2: "",
  };

  onMount(async () => {
    const res = await getForgotPwTicket(token);

    if (!isEmpty(res.error_text)) {
      errors.set({
        ...$errors,
        errorText: res.error_text,
      });
    }
  });

  const onInput = (e) => {
    errors.set({
      ...$errors,
      [e.target.name]: "",
    });
  };

  const resetPw = async () => {
    const { errorMsgs, isValid } = resetPwValidation(newPw);

    if (isValid) {
      const data = {
        ticket: token,
        password: newPw.password,
      };

      const res = await resetPassword(data);

      if (res === 0) {
        addNotification({
          text: "Successfully reset password",
          position: "top-right",
          type: "success",
          removeAfter: 4000,
        });
      } else {
        if (!isEmpty(res.error_text))
          addNotification({
            text: res.error_text,
            position: "top-right",
            type: "danger",
            removeAfter: 4000,
          });
      }
    } else {
      errors.set(errorMsgs);
    }
  };
</script>

<div
  class="d-flex justify-content-center align-items-center"
  in:fly={{ duration: 500, x: 300 }}
>
  <div class="fit-content-width text-start">
    <p class="fs-2 text-center cl-primary fw-bold">Reset Your Password</p>

    {#if !isEmpty($errors.errorText)}
      <p class="text-danger text-center">{$errors.errorText}</p>
    {/if}

    {#if $isForgotPwTicketLoading}
      <DashboardSpinner />
    {:else if isEmpty($errors.errorText)}
      <form on:submit|preventDefault={resetPw} novalidate>
        <div class="form-group mt-3">
          <label for="password" class="cl-primary">New Password</label>
          <input
            type="password"
            class={`form-control mt-2 primary-input ${
              $errors.password ? "is-invalid" : null
            }`}
            name="password"
            id="password"
            bind:value={newPw.password}
            on:input={onInput}
          />
          {#if $errors.password}
            <div class="text-danger">{$errors.password}</div>
          {/if}
        </div>
        <div class="form-group mt-3">
          <label for="password2" class="cl-primary">Confirm Password</label>
          <input
            type="password"
            class={`form-control mt-2 primary-input ${
              $errors.password2 ? "is-invalid" : null
            }`}
            name="password2"
            id="password2"
            bind:value={newPw.password2}
            on:input={onInput}
          />
          {#if $errors.password2}
            <div class="text-danger">{$errors.password2}</div>
          {/if}
        </div>
        <button type="submit" class="btn-main mt-3 full-width"
          >Reset Password</button
        >
      </form>
    {/if}
  </div>
</div>

<style lang="scss">
  @import "../../vars.scss";
</style>
