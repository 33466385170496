import api from '../utils/api';
import { } from '../store';
import { logout } from './auth';
import { getLoan } from './finance';

// Settle loan
export const loanRepay = async (data) => {
  try {
    const res = await api.post('/loan/repay', JSON.stringify(data));

    if (res.result === 0) {
      console.log(res)
      await getLoan();
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};