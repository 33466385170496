const cutDecimal = (value, num) => {
  let result;
  let strVal = value.toString().split(".");


  if (strVal.length === 1) {
    strVal = strVal[0] + ".";
    for (let i = 0; i < num; i++) {
      strVal = strVal + "0";
    }

    result = strVal;
  } else {
    if (strVal[1].length < num) {
      result = strVal[0] + "." + strVal[1];

      for (let i = 0; i < num - strVal[1].length; i++) {
        result = result + "0";
      }
    } else {
      result = strVal[0] + "." + strVal[1].slice(0, num);
    }
  }

  return result;
};

export default cutDecimal;