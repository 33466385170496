<script>
  import ApexCharts from "apexcharts";
  import { onMount } from "svelte";

  import { loan } from "../../store";

  export let series;

  let color;

  if (series >= 0 && series <= $loan.ltv_targets.start) color = "#20E647";
  else if (
    series > $loan.ltv_targets.start &&
    series <= $loan.ltv_targets.warn1
  )
    color = "#ffff00";
  else if (
    series > $loan.ltv_targets.warn1 &&
    series <= $loan.ltv_targets.warn2
  )
    color = "#ff9900";
  else if (series > $loan.ltv_targets.warn2 && series < $loan.ltv_targets.sell)
    color = "#ff0000";
  else if (series >= $loan.ltv_targets.sell && series <= 100) color = "#1a0000";

  onMount(() => {
    var options = {
      series: [series],
      chart: {
        height: 320,
        type: "radialBar",
        offsetY: -10,
        // toolbar: {
        //   show: true,
        // },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          // track: {
          //   background: "#333",
          //   startAngle: -45,
          //   endAngle: 45,
          // },
          dataLabels: {
            name: {
              fontSize: "18px",
              color: "#0a5b76",
              offsetY: 75,
            },
            value: {
              offsetY: -10,
              fontSize: "22px",
              color: "#0a5b76",
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      },
      fill: {
        colors: [color],
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 0.3,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100],
        },
      },
      stroke: {
        dashArray: 0,
      },
      labels: ["LTV Ratio"],
    };

    let chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();
  });
</script>

<div id="chart" />

<style lang="scss">
</style>
