import Validator from 'validator';

import { cursList } from '../../store';
import isEmpty from './is-empty';

const cryptoAccountValidation = (data) => {
  let errorMsgs = {};

  data.name = !isEmpty(data.name) ? data.name.toString() : '';
  data.addr = !isEmpty(data.addr) ? data.addr.toString() : '';
  data.tag = !isEmpty(data.tag) ? data.tag.toString() : '';
  data.two_fa = !isEmpty(data.two_fa) ? data.two_fa.toString() : '';

  if (isEmpty(data.cur_id)) {
    errorMsgs.cur_id = 'require';
  } else {
    let cur_list;

    cursList.subscribe(v => {
      cur_list = v;
    })

    let currency = cur_list.filter(item => item.cur_id == data.cur_id.value);

    if (currency[0].tag && Validator.isEmpty(data.tag)) {
      errorMsgs.tag = 'require';
    }
  }

  if (Validator.isEmpty(data.name)) {
    errorMsgs.name = 'require';
  }

  if (Validator.isEmpty(data.addr)) {
    errorMsgs.addr = 'require';
  }

  if (Validator.isEmpty(data.two_fa)) {
    errorMsgs.two_fa = 'require';
  } else if (!Validator.isNumeric(data.two_fa)) {
    errorMsgs.two_fa = 'Invalid type';
  } else if (!Validator.isLength(data.two_fa, { min: 6, max: 6 })) {
    errorMsgs.two_fa = 'Invalid length';
  }

  return {
    errorMsgs,
    isValid: isEmpty(errorMsgs)
  };
};

export default cryptoAccountValidation;