import { navigate } from 'svelte-navigator';

import api from '../utils/api';
import { isAuth, errors, userInfo, dashboard_warning, isWarningLoading, isUserInfoLoading, is2FASending, isAccountVerifying, isForgotPwTicketLoading } from '../store';
import { StartWebSocket } from "../socket";

// Get User
export const getUserData = async () => {
  try {
    isUserInfoLoading.set(true);

    const res = await api.get('/user');

    if (res.result === 0) {
      console.log(res.data)
      userInfo.set(res.data);
      isUserInfoLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Get warning
export const getAccountWarning = async () => {
  try {
    isWarningLoading.set(true);

    const res = await api.get('/dashboard');

    if (res.result === 0) {
      console.log(res.attention_items)
      dashboard_warning.set(res.attention_items);
      isWarningLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Delete warning
export const deleteAccountWarning = async (type) => {
  try {
    const res = await api.delete(`/dashboard/${type}`);

    if (res.result === 0) {
      console.log(res)
      await getAccountWarning();

      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Register User
export const createAccount = async newUser => {
  try {
    const res = await api.post('/user', JSON.stringify(newUser));

    if (res.result === 0) {
      errors.set({});
      return res.result;
    } else {
      if (res.result === 41 && (res.error_code === 15 || res.error_code === 14)) {
        errors.set({
          email: res.error_text
        })
      }
      if (res.result === 41 && res.error_code === 13) {
        errors.set({
          password: res.error_text
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
};

// Login User
export const login = async userData => {
  try {
    // console.log(userData)
    const res = await api.post('/session', JSON.stringify(userData));
    if (res.result === 0) {
      errors.set({});

      if (res.twofa_required) {
        localStorage.setItem("auth_token", res.token);
        navigate("/2fa", { replace: true });
      } else {
        localStorage.setItem("token", res.token);
        isAuth.set(true);
        await getUserData();
        await StartWebSocket();
        navigate("/", { replace: true });
      }
    } else {
      console.log(res)
      errors.set({
        email: res.error_text
      })
    }
  } catch (error) {
    console.log(error)
  }
};

// 2FA
export const twoFA = async code => {
  try {
    is2FASending.set(true);

    const res = await api.put('/session', JSON.stringify(code));

    if (res.result === 0) {
      const token = localStorage.getItem("auth_token");
      localStorage.removeItem("auth_token");
      localStorage.setItem("token", token);
      isAuth.set(true);
      await getUserData();
      await StartWebSocket();
      navigate("/", { replace: true });
      is2FASending.set(false);
      return res.result;
    } else {
      console.log(res);
      is2FASending.set(false);
      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Update User Info
export const updateUserInfo = async userData => {
  try {
    // console.log(userData);
    const res = await api.put('/user', JSON.stringify(userData));
    console.log(res);
    if (res.result === 0) {
      errors.set({});
      await getUserData();
      return res;
    } else {
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Change password
export const changePw = async newPw => {
  try {
    const res = await api.put('/password', JSON.stringify(newPw));

    if (res.result === 0) {
      console.log(res);
      errors.set({});

      return res.result;
    } else {
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Reset password
export const sendResetPwRequest = async email => {
  try {
    const res = await api.post('/forgotpassword', JSON.stringify(email));

    if (res.result === 0) {
      console.log(res);

      return res.result;
    } else {
      console.log(res);
      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Settings
export const setting = async data => {
  try {
    const res = await api.put('/settings', JSON.stringify(data));

    if (res.result === 0) {
      console.log(res);

      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Email verification
export const accountVerify = async ticket => {
  try {
    isAccountVerifying.set(true);

    const res = await api.post('/email_verify_ticket', JSON.stringify(ticket));

    if (res.result === 0) {
      console.log(res);
      isAccountVerifying.set(false);
      return res.result;
    } else {
      console.log(res);
      isAccountVerifying.set(false);
      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Get forgort password ticket
export const getForgotPwTicket = async ticket => {
  try {
    isForgotPwTicketLoading.set(true);

    const res = await api.get(`/forgotpassword_ticket/${ticket}`);

    if (res.result === 0) {
      isForgotPwTicketLoading.set(false);
      return res.result;
    } else {
      isForgotPwTicketLoading.set(false);
      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Reset password
export const resetPassword = async data => {
  try {
    const res = await api.post('/forgotpassword_ticket', JSON.stringify(data));

    console.log(res);
    if (res.result === 0) {
      navigate("/signin", { replace: true });
      return res.result;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Logout
export const logout = async () => {
  try {
    await api.delete('/session');
    localStorage.removeItem("token");
    navigate("/signin", { replace: true });
    isAuth.set(false);
  } catch (error) {
    console.log(error);
  }
}