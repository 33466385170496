<script>
  import { fly } from "svelte/transition";
  import { getNotificationsContext } from "svelte-notifications";

  import {
    cursList,
    userInfo,
    adjustCur,
    adjustCollateralModal,
  } from "../../store";
  import isEmpty from "../../utils/validation/is-empty";
  import insertThousandSeparator from "../../utils/thousand_separator";

  import { getAdjust } from "../../services/collateral";

  const { addNotification } = getNotificationsContext();

  export let collateral;

  let currency = $cursList.find(
    (item) => item.cur_id == collateral[0].substr(1, 1)
  );
  let local_currency = $cursList.find(
    (item) => item.cur_id == $userInfo.local_cur_id
  );

  let s0;
  let s1;
  let s2;

  if (!isEmpty(collateral[1].w2)) {
    s2 = collateral[1].w2.s2;

    if (isEmpty(collateral[1].w2.s0)) s0 = 0;
    if (isEmpty(collateral[1].w2.s1)) s1 = 0;

    s2 += s1 + s0;
  }

  let price1 = collateral[1].price[0];
  let price2 = collateral[1].price[1];
  let price;

  if (price1 >= price2) price = price1;
  else price = price2;

  const openModal = async () => {
    const res = await getAdjust(collateral[0].substr(1, 1));

    if (res === 0) {
      adjustCur.set(currency);
      adjustCollateralModal.set(true);
    } else {
      if (!isEmpty(res.error_text))
        addNotification({
          text: res.error_text,
          position: "top-right",
          type: "danger",
          removeAfter: 4000,
        });
    }
  };
</script>

<div
  class="row mx-0 py-1 my-1 transaction-item"
  in:fly={{ duration: 500, y: 500 }}
>
  <div class="col-2 d-flex flex-wrap justify-content-center align-items-center">
    <div class="d-flex justify-content-center align-items-center">
      <img
        src={`../../vendor/image/${currency.icon_image}`}
        alt="asset"
        class="asset-icon me-2"
      />
      <span>{currency.symbol}</span>
    </div>
  </div>
  <div class="col-4 d-flex justify-content-center align-items-center">
    {#if isEmpty(s2) || s2 === 0}
      -
    {:else}
      {insertThousandSeparator(s2.toFixed(currency.dec))}
    {/if}
  </div>
  <div class="col-4 d-flex justify-content-center align-items-center">
    {#if isEmpty(s2) || s2 === 0}
      -
    {:else}
      {local_currency.symbol}
      {insertThousandSeparator((price * s2).toFixed(local_currency.dec))}
    {/if}
  </div>
  <div
    class="col-2 d-flex flex-column justify-content-center align-items-center"
  >
    <button type="button" class="btn-orange" on:click={openModal}>
      Adjust
    </button>
  </div>
</div>

<style lang="scss">
  @import "../../vars.scss";

  .asset-icon {
    width: 1.8rem;
  }

  .transaction-item {
    border-radius: 0.2rem;
  }

  .transaction-item:hover {
    background-color: $trans-item-hover;
  }
</style>
