<script>
  import Modal from "sv-bootstrap-modal";
  import Select from "svelte-select";
  import { getNotificationsContext } from "svelte-notifications";

  import {
    newBankModal,
    errors,
    fiat_currencies,
    bank_items,
  } from "../../store";
  import isEmpty from "../../utils/validation/is-empty";

  import bankAccountValidation from "../../utils/validation/bankAccountValidation";
  import { createBankAccount } from "../../services/create-account";

  const { addNotification } = getNotificationsContext();

  let bank_id;
  let bank_account;
  let cur_id;
  let two_fa;

  let currencyItems;
  let bankItems;
  let modalState;

  fiat_currencies.subscribe((v) => {
    currencyItems = v;
  });
  bank_items.subscribe((v) => {
    bankItems = v;
  });
  newBankModal.subscribe((v) => {
    modalState = v;
  });

  const onClose = () => {
    newBankModal.set(false);
  };

  const handleSelectCurrency = (e) => {
    cur_id = e.detail;

    errors.set({
      ...$errors,
      currency: "",
    });
  };

  const handleClearCurrency = () => {
    cur_id = undefined;
  };

  const handleSelectBank = (e) => {
    bank_id = e.detail;

    errors.set({
      ...$errors,
      bank_id: "",
    });
  };

  const handleClearBank = () => {
    bank_id = undefined;
  };

  const onInput = (e) => {
    errors.set({
      ...$errors,
      [e.target.name]: "",
    });
  };

  const createNewAccount = async () => {
    let data = {
      cur_id: cur_id,
      bank_id: bank_id,
      bank_account: bank_account,
      two_fa: two_fa,
    };

    const { errorMsgs, isValid } = bankAccountValidation(data);

    if (isValid) {
      let newAccount = {
        bank_account: bank_account,
        bank_id: bank_id.value,
        two_fa: two_fa,
      };

      const res = await createBankAccount(newAccount, cur_id.value);

      if (res === 0) {
        addNotification({
          text: "Successfully created",
          position: "top-right",
          type: "success",
          removeAfter: 4000,
        });
      } else {
        if (!isEmpty(res.error_text))
          addNotification({
            text: res.error_text,
            position: "top-right",
            type: "danger",
            removeAfter: 4000,
          });
      }
    } else errors.set(errorMsgs);
  };

  $: if (!modalState) {
    errors.set({});
    cur_id = undefined;
    bank_id = undefined;
    bank_account = "";
    two_fa = undefined;
  }
  $: if (currencyItems.length === 1 && modalState) cur_id = currencyItems[0];
</script>

<Modal bind:open={$newBankModal} dialogClasses="modal-dialog-centered">
  <div class="modal-header">
    <h5 class="modal-title cl-primary">Add Bank Account</h5>
    <button type="button" class="btn-close" on:click={onClose} />
  </div>

  <div class="modal-body text-start">
    <form on:submit|preventDefault={createNewAccount} novalidate>
      <div class="form-group">
        <label for="currency" class="cl-primary mb-2">Currency</label>
        <Select
          items={currencyItems}
          value={cur_id}
          id="currency"
          containerClasses="primary-select"
          on:select={handleSelectCurrency}
          on:clear={handleClearCurrency}
        />
        {#if $errors.currency}
          <div class="text-danger">{$errors.currency}</div>
        {/if}
      </div>

      <div class="form-group mt-3">
        <label for="bank_id" class="cl-primary mb-2">Bank</label>
        <Select
          items={bankItems}
          value={bank_id}
          id="bank_id"
          containerClasses="primary-select"
          on:select={handleSelectBank}
          on:clear={handleClearBank}
        />
        {#if $errors.bank_id}
          <div class="text-danger">{$errors.bank_id}</div>
        {/if}
      </div>

      <div class="form-group mt-3">
        <label for="bank_account" class="cl-primary">Account Number</label>
        <input
          type="number"
          class={`form-control mt-2 primary-input ${
            $errors.bank_account ? "is-invalid" : null
          }`}
          name="bank_account"
          id="bank_account"
          bind:value={bank_account}
          on:input={onInput}
        />
        {#if $errors.bank_account}
          <div class="text-danger">{$errors.bank_account}</div>
        {/if}
      </div>

      <div class="form-group mt-3">
        <label for="two_fa" class="cl-primary">2FA Code</label>
        <input
          type="number"
          class={`form-control mt-2 primary-input ${
            $errors.two_fa ? "is-invalid" : null
          }`}
          name="two_fa"
          id="two_fa"
          bind:value={two_fa}
          on:input={onInput}
        />
        {#if $errors.two_fa}
          <div class="text-danger">{$errors.two_fa}</div>
        {/if}
      </div>

      <div class="d-flex justify-content-end mt-3">
        <button type="submit" class="btn-main">Save Account</button>
      </div>
    </form>
  </div>
</Modal>

<style lang="scss">
</style>
