<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { navigate } from "svelte-navigator";
  import { getNotificationsContext } from "svelte-notifications";
  import { Icon } from "svelte-fontawesome";
  import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

  import { twoFA } from "../../services/auth";
  import isEmpty from "../../utils/validation/is-empty";
  import { is2FASending } from "../../store";

  import DashboardSpinner from "../../components/loading/DashboardSpinner.svelte";

  const { addNotification } = getNotificationsContext();

  let code = "";

  onMount(() => {
    if (localStorage.token) {
      navigate("/", { replace: true });
    }
  });

  const gotoSignin = () => {
    navigate("/signin", { replace: true });
  };

  const onInput = async (e) => {
    if (code.length === 6) {
      const data = {
        "2fa": code,
      };

      const res = await twoFA(data);

      if (res !== 0) {
        if (!isEmpty(res.error_text))
          addNotification({
            text: res.error_text,
            position: "top-right",
            type: "danger",
            removeAfter: 4000,
          });
      }
    }
  };
</script>

<div class="two-fa" in:fly={{ duration: 500, x: 300 }}>
  <div class="back fs-4" on:click={gotoSignin}>
    <Icon icon={faArrowAltCircleLeft} />
  </div>

  <div class="fit-content-width text-start">
    <p class="fs-2 cl-primary fw-bold">Two Factor Authentication</p>

    <div class="form-group">
      <label for="code" class="cl-primary">Enter authentication code:</label>

      {#if $is2FASending}
        <DashboardSpinner />
      {:else}
        <input
          type="text"
          class={`form-control mt-2 primary-input`}
          name="code"
          id="code"
          bind:value={code}
          on:input={onInput}
        />
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../vars.scss";

  .two-fa {
    // height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    color: $primary;
    transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    &:hover {
      color: $primary-lighter;
    }
  }
</style>
