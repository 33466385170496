import Validator from 'validator';

import { cursList } from '../../store';
import isEmpty from './is-empty';

const bankAccountValidation = (data) => {
  let errorMsgs = {};

  data.bank_account = !isEmpty(data.bank_account) ? data.bank_account.toString() : '';
  data.two_fa = !isEmpty(data.two_fa) ? data.two_fa.toString() : '';

  if (isEmpty(data.cur_id)) {
    errorMsgs.currency = 'require';
  }

  if (isEmpty(data.bank_id)) {
    errorMsgs.bank_id = 'require';
  }

  if (Validator.isEmpty(data.bank_account)) {
    errorMsgs.bank_account = 'require';
  } else if (!Validator.isNumeric(data.bank_account)) {
    errorMsgs.bank_account = 'Invalid type';
  } else if (!Validator.isLength(data.bank_account, { min: 6, max: 14 })) {
    errorMsgs.bank_account = 'Invalid length';
  }

  if (Validator.isEmpty(data.two_fa)) {
    errorMsgs.two_fa = 'require';
  } else if (!Validator.isNumeric(data.two_fa)) {
    errorMsgs.two_fa = 'Invalid type';
  } else if (!Validator.isLength(data.two_fa, { min: 6, max: 6 })) {
    errorMsgs.two_fa = 'Invalid length';
  }

  return {
    errorMsgs,
    isValid: isEmpty(errorMsgs)
  };
};

export default bankAccountValidation;