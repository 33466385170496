import { readable, writable } from 'svelte/store';

import isEmpty from './utils/validation/is-empty';

export const apiURL = readable("https://api.coinstash.co.za");

// User
export const isAuth = writable(false);
export const userInfo = writable({});
export const isUserInfoLoading = writable(false);
export const isAccountVerifying = writable(false);
export const isForgotPwTicketLoading = writable(false);
export const signupSuccessModal = writable(false);
// Error message
export const errors = writable({});
// Router
export const profilePage = writable();
export const createAccount = writable();
// Currency
export const cursList = writable([]);
export const curTypes = writable([]);
export const balances = writable([]);
export const pendTrans = writable([]);
export const trans = writable([]);
export const loan = writable({});
export const transType = readable([
  "Crypto deposit",
  "Crypto withdrawal",
  "Fiat withdrawal",
  "Fiat deposit",
  "Forced sell collateral",
  "Interest charged",
  "Interest earned",
  "Trade",
  "Fee charged",
  "Borrow",
  "Repay or Settle",
  "Spot => Collateral",
  "Collateral => Spot"
]);
// Loading
export const isCurListLoading = writable(true);
export const isCurTypeLoading = writable(true);
export const isBalanceLoading = writable(true);
export const isPendTransLoading = writable(true);
export const isLoanLoading = writable(true);
export const isTransLoading = writable(true);
export const isFiatWhitelistLoading = writable(true);
export const isCryptoWhitelistLoading = writable(true);
export const isBankListLoading = writable(true);
// History pagination
export const page_size = writable(10);
export const current_page = writable(1);
export const total_trans = writable(0);
// Withdraw
export const fiatWhitelist = writable([]);
export const cryptoWhitelist = writable([]);
export const asset_items = writable([]);
export const account_items = writable([]);
export const fiat_items = writable([]);
// export const crypto_items = writable([]);
export const asset_amounts = writable([]);
export const isWithdrawFiat = writable(false);
export const isWithdrawCrypto = writable(false);

export const initializeData = () => {
  let assets = [];
  // let cryptoAccounts = [];
  let fiatAccounts = [];
  let assetBalance = [];
  let bankList;

  bank_list.subscribe(v => {
    bankList = v;
  })

  balances.subscribe(_balances => {
    _balances.forEach((bItem) => {
      let currency;
      let s2 = 0;
      let s1 = 0;
      let s0 = 0;

      cursList.subscribe(_curList => {
        currency = _curList.find(
          (item) => item.cur_id == bItem[0].substr(1, 1)
        );
      })

      assets.push({
        value: bItem[0].substr(1, 1),
        label: currency.symbol + " " + "(" + currency.name + ")",
      });

      if (isEmpty(bItem[1].w0.s2)) s2 = 0;
      else s2 = bItem[1].w0.s2;

      if (isEmpty(bItem[1].w0.s1)) s1 = 0;
      else s1 = bItem[1].w0.s1;

      if (isEmpty(bItem[1].w0.s0)) s0 = 0;
      else s0 = bItem[1].w0.s0;

      assetBalance.push({
        cur_id: bItem[0].substr(1, 1),
        amount: s2 + s1 + s0,
        dec_withdraw: currency.dec_withdraw,
        min_withdraw: currency.min_withdraw,
        fee: currency.withdraw_fee,
      });
    })
  });

  fiatWhitelist.subscribe(_fiatWhitelist => {
    _fiatWhitelist.forEach(item => {
      if (item.state === 'confirmed') {
        let bank = bankList.find(bank => bank.bank_id == item.bank_id);

        fiatAccounts.push({
          value: item.id,
          label: bank.name + " " + item.account_number
        })
      }
    })
  });

  // cryptoWhitelist.subscribe(_cryptoWhitelist => {
  //   _cryptoWhitelist.forEach(item => {
  //     if (item.state === 'confirmed')
  //       cryptoAccounts.push({
  //         value: item.id,
  //         label: item.name
  //       })
  //   })
  // });

  asset_items.set(assets);
  fiat_items.set(fiatAccounts);
  // crypto_items.set(cryptoAccounts);
  asset_amounts.set(assetBalance);
}
// Create Bank & Crypto Accounts
export const bank_list = writable([]);
export const total_banks = writable(0);
export const total_cryptos = writable(0);
export const bank_current_page = writable(1);
export const bank_page_size = writable(10);
export const crypto_current_page = writable(1);
export const crypto_page_size = writable(10);
export const newBankModal = writable(false);
export const newCryptoModal = writable(false);
export const fiat_currencies = writable([]);
export const crypto_currencies = writable([]);
export const bank_items = writable([]);
export const filterCurrency = () => {
  let fiats;
  let cryptos;
  let cur_list;
  let cur_type;
  let bankList;
  let bankItems = [];
  let fiatCurrencies = [];
  let cryptoCurrencies = [];

  cursList.subscribe(v => {
    cur_list = v;
  })
  curTypes.subscribe(v => {
    cur_type = v;
  })
  bank_list.subscribe(v => {
    bankList = v;
  })

  fiats = cur_list.filter(currency => {
    let curType = cur_type.find(type => type.cur_type_id == currency.cur_type);
    return curType.name === 'fiat';
  });
  cryptos = cur_list.filter(currency => {
    let curType = cur_type.find(type => type.cur_type_id == currency.cur_type);
    return curType.name !== 'fiat';
  });

  fiats.forEach(item => {
    fiatCurrencies.push({
      value: item.cur_id,
      label: item.symbol + " " + "(" + item.name + ")"
    })
  })
  cryptos.forEach(item => {
    cryptoCurrencies.push({
      value: item.cur_id,
      label: item.symbol + " " + "(" + item.name + ")"
    })
  })

  bankList.forEach(item => {
    bankItems.push({
      value: item.bank_id,
      label: item.name
    })
  })

  bank_items.set(bankItems);
  fiat_currencies.set(fiatCurrencies);
  crypto_currencies.set(cryptoCurrencies);
}
// Uploading the document and images
export const isUploading = writable(false);
export const uploadType = writable();
// Collateral
export const isCollateralLoading = writable(false);
export const isAdjustLoading = writable(false);
export const collaterals = writable([]);
export const adjust = writable({});
export const adjustCur = writable({});
export const adjustCollateralModal = writable(false);
// Deposit
export const deposit_assets = writable([]);
export const fiat_deposit = writable({});
export const crypto_deposit = writable({});
export const isFiatDepositLoading = writable(false);
export const isCryptoDepositLoading = writable(false);
export const filterDepositAssets = () => {
  let cur_list;
  let temp = [];

  cursList.subscribe(v => {
    cur_list = v;
  });

  cur_list.forEach(item => {
    if (item.can_deposit)
      temp.push({
        value: item.cur_id,
        label: item.symbol + " " + "(" + item.name + ")"
      });
  });

  deposit_assets.set(temp);
}
// Settle
export const settleModal = writable(false);
// Borrow
export const borrowModal = writable(false);
// 2FA
export const data2FA = writable({});
export const is2FALoading = writable(false);
export const is2FASending = writable(false);
// Reset Pasword
export const resetPwModal = writable(false);
// Dashboard warning
export const dashboard_warning = writable([]);
export const isWarningLoading = writable(false);
// PWA install
export const showInstallBtn = writable(false);