<script>
  import Modal from "sv-bootstrap-modal";
  import { navigate } from "svelte-navigator";

  import { signupSuccessModal } from "../../store";
  import isEmpty from "../../utils/validation/is-empty";

  const onClose = () => {
    signupSuccessModal.set(false);
  };

  const onOK = () => {
    navigate("/signin", { replace: true });
    signupSuccessModal.set(false);
  };
</script>

<Modal bind:open={$signupSuccessModal} dialogClasses="modal-dialog-centered">
  <div class="modal-header">
    <h5 class="modal-title cl-primary">Successfully created account</h5>
    <button type="button" class="btn-close" on:click={onClose} />
  </div>

  <div class="modal-body text-start">
    <p class="cl-primary">We have emailed you with further instructions.</p>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn-main" on:click={onOK}>OK</button>
    </div>
  </div>
</Modal>

<style lang="scss">
</style>
