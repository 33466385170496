import { logout } from './services/auth';

navigator.serviceWorker.register('service-worker.js');

export let webSocket = false;

export const StartWebSocket = () => {
  console.log('Starting web socket');
  webSocket = new WebSocket("wss://api.coinstash.co.za:8080/");

  webSocket.onopen = (e) => {
    console.log("[open] Connection established");
    console.log("Sending to server");
    // console.log({ WebSocket: webSocket });
    // if (webSocket.readyState !== 0 && webSocket.readyState !== 1)
    webSocket.send(JSON.stringify({ t: 'auth', token: localStorage.token }));
  };

  webSocket.onmessage = (event) => {
    console.log(`[message] Data received from server: ${event.data}`);

    let obj = JSON.parse(event.data);

    switch (obj.t) {
      case 'notify':
        Notification.requestPermission(result => {
          if (result === 'granted') {
            navigator.serviceWorker.getRegistration().then(reg => {
              let icon = 'images/icons/icon-192x192.png';
              let body = obj.message;
              let options = { body, icon, badge: 'vendor/image/badge/badge.png' };
              reg.showNotification('Coin Stash', options);
            });
          }
        });
        break;
      case 'logout':
        logout();
        break;
    }
  };


  webSocket.onclose = (event) => {
    if (event.wasClean) {
      console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
    } else {
      console.log('[close] Connection died');
    }
    // webSocket = false;
    // webSocket.close();

    setTimeout(() => {
      StartWebSocket();
    }, 4000);
  };

  webSocket.onerror = (error) => {
    console.log(`[error] ${error.message}`);
    // webSocket = false;
    webSocket.close();

    // setTimeout(() => {
    //   StartWebSocket();
    // }, 1000);
  };
}
