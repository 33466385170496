import api from '../utils/api';
import { fiatWhitelist, cryptoWhitelist, isFiatWhitelistLoading, isCryptoWhitelistLoading, isWithdrawFiat, isWithdrawCrypto } from '../store';
import { logout } from './auth';
import { getBalances } from './finance';

// Get fiat whitelist
export const getFiatWhitelist = async () => {
  try {
    isFiatWhitelistLoading.set(true);
    const res = await api.get('/fiat/whitelist');

    if (res.result === 0) {
      console.log(res.data)
      fiatWhitelist.set(res.data);
      isFiatWhitelistLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Get crypto whitelist
export const getCryptoWhitelist = async () => {
  try {
    isCryptoWhitelistLoading.set(true);
    const res = await api.get('/crypto/whitelist');

    if (res.result === 0) {
      console.log(res.data)
      cryptoWhitelist.set(res.data);
      isCryptoWhitelistLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Withdraw fiat
export const withdrawFiat = async (data) => {
  try {
    isWithdrawFiat.set(true);
    const res = await api.post('/fiat/withdraw', data);

    if (res.result === 0) {
      console.log(res)
      isWithdrawFiat.set(false);
      await getBalances();
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Withdraw crypto
export const withdrawCrypto = async (data) => {
  try {
    isWithdrawCrypto.set(true);
    const res = await api.post('/crypto/withdraw', data);

    if (res.result === 0) {
      console.log(res)
      isWithdrawCrypto.set(false);
      await getBalances();
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};