<script>
  import { Icon } from "svelte-fontawesome";
  import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

  export let disabledPrev;
  export let prevPage;

  const setPrevPage = () => {
    if (!disabledPrev) prevPage();
  };
</script>

<div
  class={`${disabledPrev ? "disable-btn" : "prev-btn"}`}
  on:click={setPrevPage}
>
  <Icon icon={faChevronLeft} />
</div>

<style lang="scss">
  @import "../../vars.scss";

  .disable-btn {
    margin-top: 2px;
    color: $disabled;
    padding: 0.5rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }

  .prev-btn {
    margin-top: 2px;
    color: $dark-blue;
    padding: 0.5rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }

  .prev-btn:hover {
    cursor: pointer;
    color: $primary;
    background-color: $trans-item-hover;
  }
</style>
