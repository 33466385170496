<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { Icon } from "svelte-fontawesome";
  import { faSync } from "@fortawesome/free-solid-svg-icons";
  import { PaginationNav } from "svelte-paginate";

  import {
    trans,
    isTransLoading,
    isCurListLoading,
    page_size,
    current_page,
    total_trans,
  } from "../store";
  import { getTrans } from "../services/finance";

  import isLastPage from "../utils/pagination/isLastPage";

  import DashboardSpinner from "../components/loading/DashboardSpinner.svelte";
  import TransHistoryItem from "../components/history/TransHistoryItem.svelte";
  import PrevBtn from "../components/pagination/PrevBtn.svelte";
  import NextBtn from "../components/pagination/NextBtn.svelte";

  let currentPage;
  let pageSize;
  let totalTrans;

  let disabledPrev = false;
  let disabledNext = false;

  page_size.subscribe((value) => {
    pageSize = value;
  });
  current_page.subscribe((value) => {
    currentPage = value;
  });
  total_trans.subscribe((v) => {
    totalTrans = v;
  });

  onMount(async () => {
    await getTrans();

    if (isLastPage(currentPage, "history")) disabledNext = true;
    else disabledNext = false;
  });

  const reloadHistory = async () => {
    await getTrans();
  };

  const onSetPage = async (e) => {
    current_page.set(e.detail.page);

    if (isLastPage(currentPage, "history")) disabledNext = true;
    else disabledNext = false;

    await getTrans();
  };

  const prevPage = async () => {
    if (currentPage !== 1) {
      current_page.set(currentPage - 1);

      await getTrans();
    }
  };

  const nextPage = async () => {
    if (!isLastPage(currentPage, "history")) {
      current_page.set(currentPage + 1);
      await getTrans();
    }
  };

  $: if (currentPage === 1) disabledPrev = true;
  else disabledPrev = false;
  $: if (isLastPage(currentPage, "history")) disabledNext = true;
  else disabledNext = false;
</script>

<div in:fly={{ duration: 400, x: -300 }}>
  <div class="history">
    <div class="sync-icon" on:click={reloadHistory}>
      <Icon icon={faSync} />
    </div>
    <p class="fs-5 cl-primary">History of Transactions</p>

    <div class="history-field">
      <div class="history-field-size">
        <div class="history-header">
          <div class="row mx-0">
            <div class="col-2 text-center cl-primary">Date/Time</div>
            <div class="col-2 text-center cl-primary">Transaction</div>
            <div class="col-2 text-center cl-primary">Asset</div>
            <div class="col-3 text-center cl-primary">Amount</div>
            <div class="col-3 text-center cl-primary">Fee</div>
          </div>
        </div>

        <div class="history-list">
          {#if $isTransLoading || $isCurListLoading}
            <DashboardSpinner />
          {:else}
            {#each $trans as transaction}
              <TransHistoryItem {transaction} />
            {/each}
          {/if}
        </div>
      </div>
    </div>

    <div class="page d-flex justify-content-center align-items-center">
      <PrevBtn {prevPage} {disabledPrev} />
      <PaginationNav
        totalItems={totalTrans}
        {pageSize}
        {currentPage}
        limit={1}
        showStepOptions={false}
        on:setPage={onSetPage}
      />
      <NextBtn {nextPage} {disabledNext} />
    </div>
  </div>
</div>

<style lang="scss">
  @import "../vars.scss";

  .history {
    position: relative;
    background-color: $card-bg;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.2rem;
    padding: 0.8rem;
  }

  .sync-icon {
    background-color: $primary;
    border-radius: 50%;
    padding: 0.3rem;
    position: absolute;
    right: 0.8rem;
    height: 2.3rem;
    width: 2.3rem;
    color: $realod-icon;
  }

  .sync-icon:hover {
    cursor: pointer;
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transition: transform 1s ease-in-out;
    -webkit-transition: transform 1s ease-in-out;
  }

  .history-field {
    // position: relative;
    background-color: $card-content-bg;
    padding: 0 0.5rem 0.5rem 0.5rem;
    border-radius: 0.2rem;
    overflow: auto;
  }

  .history-field-size {
    min-width: 600px;
    max-height: 60vh;
  }

  .history-header {
    position: sticky;
    top: -1px;
    border-bottom: 2px solid $primary;
    padding: 1rem 0 0.5rem 0;
    background-color: $card-content-bg;
  }

  .history-list {
    padding: 0.5rem 0;
  }

  .page :global(.pagination-nav) {
    margin-top: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page :global(.option) {
    margin: 0.1rem;
    padding: 0.3rem 0.6rem;
    color: $dark-blue;
    display: flex;
    justify-content: center;
    border-radius: 0.5rem;
  }

  .page :global(.option.active) {
    color: $primary;
    background-color: $trans-item-hover;
  }

  .page :global(.option:hover) {
    cursor: pointer;
    color: $primary;
    background-color: $trans-item-hover;
  }

  .page :global(.option.prev) {
    color: $primary-dark;
  }

  .page :global(.option.next) {
    color: $primary-dark;
  }

  .page :global(.option.disabled) {
    color: gray;
  }
</style>
