<script>
  import { fly } from "svelte/transition";
  import { onMount } from "svelte";
  import QRCode from "qrcode";
  import { getNotificationsContext } from "svelte-notifications";

  import { get2FA, enable2FA, disable2FA } from "../../services/security";
  import { getUserData } from "../../services/auth";
  import {
    data2FA,
    userInfo,
    is2FALoading,
    isUserInfoLoading,
  } from "../../store";
  import isEmpty from "../../utils/validation/is-empty";

  import DashboardSpinner from "../loading/DashboardSpinner.svelte";

  const { addNotification } = getNotificationsContext();

  let code = "";
  let qrImage = "";

  // onMount(async () => {
  //   await get2FA();
  // });

  const enableTwoFA = async () => {
    const data = {
      "2fa": code,
    };

    const res = await enable2FA(data);

    if (res === 0) {
      code = "";

      await getUserData();

      addNotification({
        text: "Successfully enabled 2fa",
        position: "top-right",
        type: "success",
        removeAfter: 4000,
      });
    } else {
      if (!isEmpty(res.error_text))
        addNotification({
          text: res.error_text,
          position: "top-right",
          type: "danger",
          removeAfter: 4000,
        });
    }
  };

  const disableTwoFA = async () => {
    const data = {
      "2fa": code,
    };

    const res = await disable2FA(data);

    if (res === 0) {
      code = "";

      await getUserData();

      addNotification({
        text: "Successfully disabled 2fa",
        position: "top-right",
        type: "warning",
        removeAfter: 4000,
      });
    } else {
      if (!isEmpty(res.error_text))
        addNotification({
          text: res.error_text,
          position: "top-right",
          type: "danger",
          removeAfter: 4000,
        });
    }
  };

  $: if (!$userInfo.twofa_enabled) get2FA();

  $: if (!isEmpty($data2FA)) {
    const opts = {
      errorCorrectionLevel: "H",
      type: "terminal",
      quality: 0.95,
      margin: 2,
      color: {
        dark: "#0a5b76",
        light: "#FFF",
      },
    };
    QRCode.toDataURL($data2FA.qr_url, opts)
      .then((url) => {
        qrImage = url;
      })
      .catch((err) => console.log(err));
  }
</script>

<div in:fly={{ duration: 500, x: 300 }} class="d-flex justify-content-center">
  <div class="security-card">
    <p class="fs-2 fw-bold cl-primary">Security</p>

    {#if $is2FALoading || $isUserInfoLoading}
      <DashboardSpinner />
    {:else if $userInfo.twofa_enabled}
      <div>
        <div class="input-group align-items-center">
          <label for="2faCode" class="cl-dark-blue me-2">Enter 2FA Code:</label>
          <input
            type="text"
            class={`form-control mt-2 second-input`}
            name="2faCode"
            id="2faCode"
            bind:value={code}
          />
        </div>

        <div class="d-flex justify-content-center mt-3">
          <button type="button" class="btn-blue" on:click={disableTwoFA}>
            <span class="">Disable 2FA</span>
          </button>
        </div>
      </div>
    {:else}
      <div class="d-flex justify-content-center">
        <img src={qrImage} alt="qrcode" id="qrcode" />
      </div>

      <p class="cl-dark-blue mb-2">or manullay enter this secret:</p>

      <p class="cl-dark-blue fs-4 fw-bold my-2">{$data2FA.seed}</p>

      <div>
        <div class="input-group align-items-center">
          <label for="2faCode" class="cl-dark-blue me-2">Enter 2FA Code:</label>
          <input
            type="text"
            class={`form-control mt-2 second-input`}
            name="2faCode"
            id="2faCode"
            bind:value={code}
          />
        </div>

        <div class="d-flex justify-content-center mt-3">
          <button type="button" class="btn-main" on:click={enableTwoFA}>
            <span class="">Enable 2FA</span>
          </button>
        </div>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  @import "../../vars.scss";

  .security-card {
    position: relative;
    background-color: $card-bg;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.3rem;
    padding: 0.8rem;
    width: 100%;
    max-width: 450px;
  }

  #qrcode {
    width: 70%;
    min-width: 280px;
    height: auto;
    margin: 0.5rem;
    display: inline-block;
  }
</style>
