import api from '../utils/api';
import { cursList, curTypes, balances, pendTrans, trans, loan, total_trans, page_size, current_page, isBalanceLoading, isPendTransLoading, isLoanLoading, isTransLoading, isCurListLoading, isCurTypeLoading } from '../store';
import { logout } from './auth';

// Get currencies list
export const getCursList = async () => {
  try {
    isCurListLoading.set(true);

    const res = await api.get('/curs/list');

    if (res.result === 0) {
      cursList.set(res.data);
      console.log(res.data);
      isCurListLoading.set(false);
    } else {
      console.log(res);
    }
  } catch (error) {
    console.log(error)
  }
};

// Get currency types
export const getCurTypes = async () => {
  try {
    isCurTypeLoading.set(true);

    const res = await api.get('/curs/types');

    if (res.result === 0) {
      curTypes.set(res.data);
      console.log(res.data);
      isCurTypeLoading.set(false);
    } else {
      console.log(res);
    }
  } catch (error) {
    console.log(error)
  }
};

// Get currency types
export const getBalances = async () => {
  try {
    isBalanceLoading.set(true);
    const res = await api.get('/balances');

    if (res.result === 0) {
      console.log(res.data);
      let _balances = Object.entries(res.data);

      balances.set(_balances);

      isBalanceLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Get pending transactions
export const getPendTrans = async () => {
  try {
    isPendTransLoading.set(true);
    const res = await api.get('/trans?pend');

    if (res.result === 0) {
      pendTrans.set(res.data);
      isPendTransLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Get transactions
export const getTrans = async () => {
  try {
    isTransLoading.set(true);

    let count;
    let start;

    page_size.subscribe(v => {
      count = v;
    });
    current_page.subscribe(v => {
      start = count * (v - 1);
    })

    const res = await api.get(`/trans?count=${count}&start=${start}`);

    if (res.result === 0) {
      console.log(res.data)
      trans.set(res.data);
      total_trans.set(res.total_count);
      isTransLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Get loan
export const getLoan = async () => {
  try {
    isLoanLoading.set(true);
    const res = await api.get('/loans');

    if (res.result === 0) {
      console.log(res.data)
      loan.set(res.data);
      isLoanLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};