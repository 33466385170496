import api from '../utils/api';
import { data2FA, is2FALoading } from '../store';
import { logout } from './auth';

// Get 2fa
export const get2FA = async () => {
  try {
    is2FALoading.set(true);

    const res = await api.get('/2fa');

    if (res.result === 0) {
      data2FA.set(res);
      // console.log(res);
      is2FALoading.set(false);
    } else {
      is2FALoading.set(false);
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Enable 2fa
export const enable2FA = async (code) => {
  try {
    const res = await api.post('/2fa', code);

    if (res.result === 0) {
      console.log(res);
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Enable 2fa
export const disable2FA = async (code) => {
  try {
    const res = await api.delete('/2fa', JSON.stringify(code));

    if (res.result === 0) {
      console.log(res);
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};