import api from '../utils/api';
import { fiatWhitelist, cryptoWhitelist, total_banks, total_cryptos, bank_list, isFiatWhitelistLoading, isCryptoWhitelistLoading, isBankListLoading, bank_current_page, bank_page_size, crypto_current_page, crypto_page_size, newCryptoModal, newBankModal } from '../store';
import { logout } from './auth';

// Get bank list
export const getBankList = async () => {
  try {
    isBankListLoading.set(true);

    const res = await api.get("/banks/list");

    if (res.result === 0) {
      console.log(res.data)
      bank_list.set(res.data);
      isBankListLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Get fiat whitelist
export const getBankAccounts = async () => {
  try {
    isFiatWhitelistLoading.set(true);

    let count;
    let start;

    bank_page_size.subscribe(v => {
      count = v;
    });
    bank_current_page.subscribe(v => {
      start = count * (v - 1);
    })

    const res = await api.get(`/fiat/whitelist?count=${count}&start=${start}`);

    if (res.result === 0) {
      console.log(res.data)
      fiatWhitelist.set(res.data);
      total_banks.set(res.total_count);
      isFiatWhitelistLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Get crypto whitelist
export const getCryptoAccounts = async () => {
  try {
    isCryptoWhitelistLoading.set(true);

    let count;
    let start;

    crypto_page_size.subscribe(v => {
      count = v;
    });
    crypto_current_page.subscribe(v => {
      start = count * (v - 1);
    })

    const res = await api.get(`/crypto/whitelist?count=${count}&start=${start}`);

    if (res.result === 0) {
      cryptoWhitelist.set(res.data);
      total_cryptos.set(res.total_count);
      isCryptoWhitelistLoading.set(false);
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Create bank account
export const createBankAccount = async (newAccount, cur_id) => {
  try {
    const res = await api.post(`/fiat/whitelist/${cur_id}`, JSON.stringify(newAccount));

    if (res.result === 0) {
      await getBankAccounts();
      newBankModal.set(false);
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Delete bank account
export const deleteBankAccount = async (bank_acc_id) => {
  try {
    const res = await api.delete(`/fiat/whitelist/${bank_acc_id}`);

    if (res.result === 0) {
      await getBankAccounts();
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Create crypto account
export const createCryptoAccount = async (newAccount, cur_id) => {
  try {
    const res = await api.post(`/crypto/whitelist/${cur_id}`, JSON.stringify(newAccount));

    if (res.result === 0) {
      console.log(res);
      await getCryptoAccounts();
      newCryptoModal.set(false);
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Delete crypto account
export const deleteCryptoAccount = async (crypto_acc_id) => {
  try {
    const res = await api.delete(`/crypto/whitelist/${crypto_acc_id}`);

    if (res.result === 0) {
      await getCryptoAccounts();
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};