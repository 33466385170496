<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { Icon } from "svelte-fontawesome";
  import {
    faSync,
    faPlusCircle,
    faMinusCircle,
    faExclamationTriangle,
  } from "@fortawesome/free-solid-svg-icons";
  import Switch from "svelte-switch";
  import { Link } from "svelte-navigator";

  import {
    balances,
    pendTrans,
    dashboard_warning,
    showInstallBtn,
    isWarningLoading,
    isBalanceLoading,
    isPendTransLoading,
    isLoanLoading,
    isCurListLoading,
    isCollateralLoading,
    isUserInfoLoading,
  } from "../store";
  import { getBalances, getPendTrans, getLoan } from "../services/finance";
  import { getAccountWarning } from "../services/auth";
  import { getCollaterals } from "../services/collateral";
  import isEmpty from "../utils/validation/is-empty";

  import WalletItem from "../components/Dashboard/WalletItem.svelte";
  import TransactionItem from "../components/Dashboard/TransactionItem.svelte";
  import DashboardSpinner from "../components/loading/DashboardSpinner.svelte";
  import Loans from "../components/Dashboard/Loans.svelte";
  import Settle from "../components/settle-borrow/Settle.svelte";
  import Borrow from "../components/settle-borrow/Borrow.svelte";
  import WarningItem from "../components/Dashboard/WarningItem.svelte";

  let hideZeroBal = false;
  let deferredPrompt;

  onMount(async () => {
    await getAccountWarning();
    await getBalances();
    await getPendTrans();
    await getLoan();
    await getCollaterals();
  });

  const handleHideZeroBal = (e) => {
    hideZeroBal = e.detail.checked;
  };

  const reloadBalances = async () => {
    isBalanceLoading.set(true);
    await getBalances();
  };

  const reloadPenTrans = async () => {
    isPendTransLoading.set(true);
    await getPendTrans();
  };

  const reloadLoan = async () => {
    isLoanLoading.set(true);
    await getLoan();
  };

  const installApp = async () => {
    if (deferredPrompt !== null) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        showInstallBtn.set(false);
        deferredPrompt = null;
      }
    }
  };

  $: window.addEventListener("beforeinstallprompt", (e) => {
    showInstallBtn.set(true);
    deferredPrompt = e;
  });
</script>

{#if !$isUserInfoLoading}
  <Settle />
  <Borrow />
{/if}

<!-- Install PWA -->
{#if $showInstallBtn}
  <div class="pwa-install mb-4" transition:fly={{ duration: 400, x: 300 }}>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center fs-5 text-info">
        <Icon icon={faExclamationTriangle} />
        <span class="ms-2">Please install PWA</span>
      </div>

      <button type="button" class="btn-blue" on:click={installApp}
        >Install</button
      >
    </div>
  </div>
{/if}

<!-- Warnings -->
{#if !$isWarningLoading}
  <div>
    {#each $dashboard_warning as item}
      <WarningItem warning={item} />
    {/each}
  </div>
{/if}

<!-- Wallets -->
<div class="wallets" in:fly={{ duration: 400, x: 300 }}>
  <div class="sync-icon" on:click={reloadBalances}>
    <Icon icon={faSync} />
  </div>
  <p class="fs-5 cl-primary">Wallets</p>

  <div class="wallets-field">
    <div class="wallets-field-size">
      <div class="wallets-header">
        <div class="row mx-0">
          <div class="col-2 text-center cl-primary">Asset</div>
          <div class="col-4 text-center cl-primary">Spot Wallet</div>
          <div class="col-3 text-center cl-primary">Loan</div>
          <div class="col-3 text-center cl-primary">In Collateral</div>
        </div>
      </div>

      <div class="wallets-list">
        {#if $isBalanceLoading || $isCurListLoading}
          <DashboardSpinner />
        {:else}
          {#each $balances as balance}
            <WalletItem asset={balance} />
          {/each}
        {/if}
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-start align-items-center mt-2">
    <span class="me-2 cl-dark-blue">Hide zero balances</span>
    <Switch
      on:change={handleHideZeroBal}
      checked={hideZeroBal}
      onColor="#b471ae"
      activeBoxShadow="0 0 2px 3px #b471ae"
      height={25}
      width={50}
    />
  </div>

  <div class="d-flex justify-content-center mt-3">
    <Link to="/withdraw">
      <button type="button" class="btn-orange me-3">
        <Icon icon={faMinusCircle} />
        <span class="ms-2">Withdraw</span>
      </button>
    </Link>
    <Link to="/deposit">
      <button type="button" class="btn-blue">
        <Icon icon={faPlusCircle} />
        <span class="ms-2">Deposit</span>
      </button>
    </Link>
  </div>
</div>

<!-- Pending Transactions -->
{#if !isEmpty($pendTrans)}
  <div class="transactions mt-4" in:fly={{ duration: 400, x: 300 }}>
    <div class="sync-icon" on:click={reloadPenTrans}>
      <Icon icon={faSync} />
    </div>
    <p class="fs-5 cl-primary">Pending Transactions</p>

    <div class="transactions-field">
      <div class="transactions-field-size">
        <div class="transactions-header">
          <div class="row mx-0">
            <div class="col-3 text-center cl-primary">Date/Time</div>
            <div class="col-3 text-center cl-primary">Transaction</div>
            <div class="col-2 text-center cl-primary">Asset</div>
            <div class="col-2 text-center cl-primary">Amount</div>
            <div class="col-2 text-center cl-primary">Links</div>
          </div>
        </div>

        <div class="transactions-list">
          {#if $isPendTransLoading || $isCurListLoading}
            <DashboardSpinner />
          {:else}
            {#each $pendTrans as pendTran}
              <TransactionItem {pendTran} />
            {/each}
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}

<!-- Loan Health -->
<div
  class="loan mt-4 d-flex align-items-center flex-column"
  in:fly={{ duration: 400, x: 300 }}
>
  <div class="sync-icon" on:click={reloadLoan}>
    <Icon icon={faSync} />
  </div>
  <p class="fs-5 cl-primary">Loan Health</p>

  {#if $isLoanLoading || $isCurListLoading || $isCollateralLoading || $isUserInfoLoading}
    <DashboardSpinner />
  {:else}
    <Loans />
  {/if}
</div>

<style lang="scss">
  @import "../vars.scss";

  .wallets,
  .transactions,
  .loan,
  .pwa-install {
    position: relative;
    background-color: $card-bg;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.2rem;
    padding: 0.8rem;
  }

  .sync-icon {
    background-color: $primary;
    border-radius: 50%;
    padding: 0.3rem;
    position: absolute;
    right: 0.8rem;
    height: 2.3rem;
    width: 2.3rem;
    color: $realod-icon;
  }

  .sync-icon:hover {
    cursor: pointer;
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transition: transform 1s ease-in-out;
    -webkit-transition: transform 1s ease-in-out;
  }

  .wallets-field,
  .transactions-field {
    // position: relative;
    background-color: $card-content-bg;
    padding: 0 0.5rem 0.5rem 0.5rem;
    border-radius: 0.2rem;
    overflow: auto;
  }

  .wallets-field-size {
    min-width: 600px;
    // max-height: 200px;
  }

  .transactions-field-size {
    min-width: 550px;
    // max-height: 400px;
  }

  .wallets-header,
  .transactions-header {
    position: sticky;
    top: -1px;
    border-bottom: 2px solid $primary;
    padding: 1rem 0 0.5rem 0;
    background-color: $card-content-bg;
  }

  .wallets-list,
  .transactions-list {
    padding: 0.5rem 0;
  }
</style>
