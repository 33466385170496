<script>
  import { fly } from "svelte/transition";
  import FileDrop from "filedrop-svelte";
  import { Icon } from "svelte-fontawesome";
  import { faDownload } from "@fortawesome/free-solid-svg-icons";

  import { isUploading, uploadType, userInfo } from "../../store";
  import isEmpty from "../../utils/validation/is-empty";
  import { uploadFileSize } from "../../utils/constants";

  import OverlaySpinner from "../loading/OverlaySpinner.svelte";

  export let type;
  export let title;
  export let fileUpload;

  let file;
  let error;
  let state;
  let text;

  userInfo.subscribe((value) => {
    let document = value.documents.find((item) => item.type === type);

    if (!isEmpty(document))
      state = document.state.charAt(0).toUpperCase() + document.state.slice(1);

    if (state === "Reviewing") text = "We are busy reviewing this document";
    else if (state === "Accepted") text = "Document accepted";
  });

  const onFileDrop = (e) => {
    console.log(e.detail.files.accepted[0].type);
    if (isEmpty(e.detail.files.accepted) && !isEmpty(e.detail.files.rejected)) {
      file = undefined;

      if (e.detail.files.rejected[0].file.size > uploadFileSize)
        error = "Over max file size";
    } else if (!isEmpty(e.detail.files.accepted)) {
      if (
        e.detail.files.accepted[0].type === "image/jpeg" ||
        e.detail.files.accepted[0].type === "image/png" ||
        e.detail.files.accepted[0].type === "image/jpg" ||
        e.detail.files.accepted[0].type === "application/pdf"
      ) {
        error = "";
        file = e.detail.files.accepted[0];
      } else {
        file = undefined;
        error = "Invalid file type";
      }
    } else file = undefined;
  };

  const onUpload = async () => {
    await fileUpload(file, type);
  };
</script>

<div in:fly={{ duration: 500, x: 300 }}>
  <div class="filedrop-card">
    {#if $isUploading && $uploadType === type}
      <OverlaySpinner />
    {/if}

    <div class="d-flex justify-content-between align-items-end">
      <span class="cl-primary fs-5">{title}</span>
      {#if state === "Required"}
        <span class="cl-dark-blue">{state}</span>
      {/if}
    </div>

    {#if state === "Required"}
      <FileDrop
        on:filedrop={onFileDrop}
        multiple={false}
        maxSize={uploadFileSize}
      >
        <div class="filedrop-filed">
          <Icon icon={faDownload} class="fs-3" />
          <p class="mb-0 mt-3">Drop the image or PDF file here!</p>
          <small class="text-muted mt-2">Only one file, max 5 MB</small>
        </div>
      </FileDrop>
      {#if !isEmpty(file)}
        <p class="mb-0 mt-2 cl-primary">{file.name}</p>
      {/if}
      {#if !isEmpty(error)}
        <p class="mb-0 mt-2 text-danger">{error}</p>
      {/if}
      <div class="d-flex justify-content-center mt-2">
        <button type="button" class="btn-main" on:click={onUpload}
          >Upload</button
        >
      </div>
    {:else}
      <p class="my-2 cl-dark-blue">{text}</p>
    {/if}
  </div>
</div>

<style lang="scss">
  @import "../../vars.scss";

  .filedrop-card {
    position: relative;
    background-color: $card-bg;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.3rem;
    padding: 0.8rem;
    width: 100%;
  }

  .filedrop-filed {
    width: 45vw;
    min-width: 280px;
    height: fit-content;
    border-radius: 0.5rem;
    border: 2px dashed gray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $primary;
    padding: 1.5rem 1rem;
    transition: 0.2s ease;
    -webkit-transition: 0.2s ease;
  }

  .filedrop-filed:hover {
    cursor: pointer;
    color: $dark-blue;
    background-color: $filedrop-bg-hover;
  }
</style>
