<script>
  import Modal from "sv-bootstrap-modal";

  import { resetPwModal, errors } from "../../store";
  import isEmpty from "../../utils/validation/is-empty";
  import resetEmailValidation from "../../utils/validation/resetEmailValidation";
  import { sendResetPwRequest } from "../../services/auth";

  let email;
  let text;

  const onClose = () => {
    resetPwModal.set(false);
  };

  const onInput = (e) => {
    errors.set({
      ...$errors,
      [e.target.name]: "",
    });
  };

  const sendRequest = async () => {
    const resetEmail = {
      email: email,
    };

    const { errorMsgs, isValid } = resetEmailValidation(resetEmail);

    if (isValid) {
      const res = await sendResetPwRequest(resetEmail);

      if (res === 0) {
        text =
          "We have emailed you with instructions. Please check your email now.";
      } else {
        if (!isEmpty(res.error_text))
          errors.set({
            email: res.error_text,
          });
      }
    } else {
      errors.set(errorMsgs);
    }
  };

  $: if (!$resetPwModal) {
    errors.set({});
    email = "";
    text = "";
  }
</script>

<Modal bind:open={$resetPwModal} dialogClasses="modal-dialog-centered">
  <div class="modal-header">
    <h5 class="modal-title cl-primary">Reset your password</h5>
    <button type="button" class="btn-close" on:click={onClose} />
  </div>

  <div class="modal-body text-start">
    {#if !isEmpty(text)}
      <div class="my-2">
        <span class="cl-primary">{text}</span>
      </div>
    {:else}
      <form on:submit|preventDefault={sendRequest} novalidate>
        <div class="form-group">
          <label for="email" class="cl-primary">Enter your email:</label>
          <input
            type="email"
            class={`form-control mt-2 primary-input ${
              $errors.email ? "is-invalid" : null
            }`}
            name="email"
            id="email"
            bind:value={email}
            on:input={onInput}
          />
          {#if $errors.email}
            <div class="text-danger">{$errors.email}</div>
          {/if}
        </div>

        <div class="d-flex justify-content-end mt-3">
          <button type="submit" class="btn-main">Send</button>
        </div>
      </form>
    {/if}
  </div>
</Modal>

<style lang="scss">
</style>
