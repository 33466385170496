import Validator from 'validator';
import isEmpty from './is-empty';

const signinValidation = (data) => {
  let errorMsgs = {};

  data.email = !isEmpty(data.email) ? data.email.toString() : '';
  data.password = !isEmpty(data.password) ? data.password.toString() : '';

  if (Validator.isEmpty(data.email)) {
    errorMsgs.email = 'require';
  }

  if (!Validator.isEmpty(data.email) && !Validator.isEmail(data.email)) {
    errorMsgs.email = 'Invalid email';
  }

  if (Validator.isEmpty(data.password)) {
    errorMsgs.password = 'require';
  }

  return {
    errorMsgs,
    isValid: isEmpty(errorMsgs)
  };
};

export default signinValidation;