<script>
  import { onMount } from "svelte";
  import { fly, fade } from "svelte/transition";
  import Select from "svelte-select";
  import Clipboard from "svelte-clipboard";
  import { Icon } from "svelte-fontawesome";
  import {
    faClone,
    faExclamationTriangle,
  } from "@fortawesome/free-solid-svg-icons";
  import Popover from "svelte-easy-popover";

  import {
    errors,
    curTypes,
    cursList,
    deposit_assets,
    fiat_deposit,
    crypto_deposit,
    bank_list,
    isCurListLoading,
    isCurTypeLoading,
    isBankListLoading,
    filterDepositAssets,
    isFiatDepositLoading,
    isCryptoDepositLoading,
  } from "../store";

  import { getBankList } from "../services/create-account";
  import { getFiatDeposit, getCryptoDeposit } from "../services/deposit";
  import isEmpty from "../utils/validation/is-empty";

  import DashboardSpinner from "../components/loading/DashboardSpinner.svelte";

  let assetItems;
  let asset;

  let currency;
  let currencyType;

  let bank;
  let accountNum;
  let branch_code;
  let deposit_ref;

  let address;
  let blockchain;
  let warning;
  let tag;

  let copy_state = "copy";
  let referenceAccountNum;
  let referenceDepositRef;
  let referenceAddress;
  let referenceTag;

  deposit_assets.subscribe((v) => {
    assetItems = v;
  });

  onMount(async () => {
    await getBankList();
  });

  const handleSelectAsset = async (e) => {
    asset = e.detail;

    warning = undefined;
    tag = undefined;

    errors.set({
      ...$errors,
      asset: "",
    });

    currency = $cursList.find((item) => item.cur_id == asset.value);
    currencyType = $curTypes.find(
      (item) => item.cur_type_id == currency.cur_type
    );

    if (currencyType.name === "fiat") {
      isFiatDepositLoading.set(true);
      const res = await getFiatDeposit(asset.value);

      if (res === 0) {
        accountNum = $fiat_deposit.bank_account;
        branch_code = $fiat_deposit.branch_code;
        deposit_ref = $fiat_deposit.deposit_ref;
        bank = $bank_list.find((item) => item.bank_id == $fiat_deposit.bank_id);
        isFiatDepositLoading.set(false);
      } else {
        currency = undefined;
        currencyType = undefined;
        isFiatDepositLoading.set(false);
        errors.set({
          ...$errors,
          asset: "Error occurs during getting bank data",
        });
      }
    } else {
      isCryptoDepositLoading.set(true);
      const res = await getCryptoDeposit(asset.value);

      if (res === 0) {
        address = $crypto_deposit.address;
        blockchain = $crypto_deposit.blockchain;

        if ($crypto_deposit.warning !== false)
          warning = $crypto_deposit.warning;

        if ($crypto_deposit.tag !== false) tag = $crypto_deposit.tag;

        isCryptoDepositLoading.set(false);
      } else {
        currency = undefined;
        currencyType = undefined;
        isCryptoDepositLoading.set(false);
        errors.set({
          ...$errors,
          asset: "Error occurs during getting crypto address",
        });
      }
    }
  };

  const handleClearAsset = () => {
    currency = undefined;
    currencyType = undefined;

    bank = undefined;
    accountNum = undefined;
    branch_code = undefined;
    deposit_ref = undefined;

    address = undefined;
    blockchain = undefined;
    warning = undefined;
    tag = undefined;

    errors.set({});
    isFiatDepositLoading.set(false);
    isCryptoDepositLoading.set(false);
  };

  const onLeaveClipboard = () => {
    setTimeout(changeClipboardMsg, 300);
  };

  const changeClipboardMsg = () => {
    copy_state = "copy";
  };

  const onDeposit = async () => {};

  $: if (!$isCurListLoading && !$isCurTypeLoading && !$isBankListLoading)
    filterDepositAssets();
</script>

<div class="d-flex justify-content-center" in:fly={{ duration: 400, x: -300 }}>
  <div class="deposit text-start">
    <p class="fs-5 cl-primary text-center">Deposit</p>

    {#if $isCurListLoading || $isCurTypeLoading || $isBankListLoading}
      <DashboardSpinner />
    {:else}
      <form
        on:submit|preventDefault={onDeposit}
        in:fly={{ duration: 400, y: 200 }}
        novalidate
      >
        <div class="form-group">
          <label for="asset" class="cl-dark-blue mb-2">Asset:</label>
          <Select
            items={assetItems}
            value={asset}
            id="asset"
            containerClasses="deposit-select"
            on:select={handleSelectAsset}
            on:clear={handleClearAsset}
          />
          {#if $errors.asset}
            <div class="text-danger">{$errors.asset}</div>
          {/if}
        </div>

        {#if !isEmpty(currency) && !$isFiatDepositLoading && !$isCryptoDepositLoading}
          <div
            class="d-flex align-items-center my-3"
            in:fly={{ duration: 400, x: 300 }}
          >
            <span class="cl-dark-blue me-2">Currency:</span>
            <div class="d-flex align-items-center">
              <img
                src={`../../vendor/image/${currency.icon_image}`}
                alt="asset"
                class="asset-icon me-2"
              />
              <span class="cl-primary">{currency.symbol}</span>
            </div>
          </div>
        {/if}

        {#if $isFiatDepositLoading}
          <DashboardSpinner />
        {:else}
          <div in:fly={{ duration: 400, x: 300 }}>
            {#if !isEmpty(currencyType) && currencyType.name === "fiat"}
              <div class="d-flex align-items-center my-3">
                <span class="cl-dark-blue me-2">Bank:</span>
                <div class="d-flex align-items-center">
                  <img
                    src={`vendor/image/${bank.icon_image}`}
                    alt="bank"
                    class="asset-icon me-2"
                  />
                  <span class="cl-primary">{bank.name}</span>
                </div>
              </div>
              <div class="d-flex align-items-center my-3">
                <span class="cl-dark-blue me-2">Account Number:</span>
                <span class="cl-primary">{accountNum}</span>
                <Clipboard
                  text={accountNum}
                  let:copy
                  on:copy={() => {
                    copy_state = "copied";
                  }}
                >
                  <div
                    class="clipboard-icon ms-2"
                    on:click={copy}
                    on:mouseleave={onLeaveClipboard}
                    bind:this={referenceAccountNum}
                  >
                    <Icon icon={faClone} />
                  </div>
                </Clipboard>
              </div>
              <div class="d-flex align-items-center my-3">
                <span class="cl-dark-blue me-2">Branch Code:</span>
                <span class="cl-primary">{branch_code}</span>
              </div>

              <div class="d-flex align-items-center mt-3 mb-2">
                <span class="cl-dark-blue me-2">Deposit Reference:</span>
                <span class="cl-primary">{deposit_ref}</span>
                <Clipboard
                  text={deposit_ref}
                  let:copy
                  on:copy={() => {
                    copy_state = "copied";
                  }}
                >
                  <div
                    class="clipboard-icon ms-2"
                    on:click={copy}
                    on:mouseleave={onLeaveClipboard}
                    bind:this={referenceDepositRef}
                  >
                    <Icon icon={faClone} />
                  </div>
                </Clipboard>
              </div>

              <div class="d-flex align-items-start text-danger warning-text">
                <Icon icon={faExclamationTriangle} class="mt-1" />
                <span class="ms-2"
                  >Please ensure that any funds you deposit originate from a
                  bank account that is in your own name. No cash deposits or
                  cheques accepted.</span
                >
              </div>
            {/if}
          </div>
        {/if}

        {#if $isCryptoDepositLoading}
          <DashboardSpinner />
        {:else}
          <div in:fly={{ duration: 400, x: 300 }}>
            {#if !isEmpty(currencyType) && currencyType.name !== "fiat"}
              <div class="d-flex align-items-center my-3">
                <span class="cl-dark-blue me-2">Address:</span>
                <span class="cl-primary">{address}</span>
                <Clipboard
                  text={address}
                  let:copy
                  on:copy={() => {
                    copy_state = "copied";
                  }}
                >
                  <div
                    class="clipboard-icon ms-2"
                    on:click={copy}
                    on:mouseleave={onLeaveClipboard}
                    bind:this={referenceAddress}
                  >
                    <Icon icon={faClone} />
                  </div>
                </Clipboard>
              </div>
              <div class="d-flex align-items-center my-3 mb-2">
                <span class="cl-dark-blue me-2">Blockchain:</span>
                <span class="cl-primary">{blockchain}</span>
              </div>

              {#if !isEmpty(tag)}
                <div class="d-flex align-items-center mt-3 mb-1">
                  <span class="cl-dark-blue me-2">Tag:</span>
                  <span class="cl-primary">{tag}</span>
                  <Clipboard
                    text={tag}
                    let:copy
                    on:copy={() => {
                      copy_state = "copied";
                    }}
                  >
                    <div
                      class="clipboard-icon ms-2"
                      on:click={copy}
                      on:mouseleave={onLeaveClipboard}
                      bind:this={referenceTag}
                    >
                      <Icon icon={faClone} />
                    </div>
                  </Clipboard>
                </div>
              {/if}

              {#if !isEmpty(warning)}
                <div class="d-flex align-items-start text-danger warning-text">
                  <Icon icon={faExclamationTriangle} class="mt-1" />
                  <span class="ms-2">{warning}</span>
                </div>
              {/if}
            {/if}
          </div>
        {/if}
      </form>
    {/if}

    <Popover
      triggerEvents={["focus", "hover"]}
      referenceElement={referenceAccountNum}
      placement="top"
      spaceAway={3}
      closeOnClickAway
    >
      <div class="copy-state" transition:fade={{ duration: 250 }}>
        {copy_state}
      </div>
    </Popover>

    <Popover
      triggerEvents={["focus", "hover"]}
      referenceElement={referenceDepositRef}
      placement="top"
      spaceAway={3}
      closeOnClickAway
    >
      <div class="copy-state" transition:fade={{ duration: 250 }}>
        {copy_state}
      </div>
    </Popover>

    <Popover
      triggerEvents={["focus", "hover"]}
      referenceElement={referenceAddress}
      placement="top"
      spaceAway={3}
      closeOnClickAway
    >
      <div class="copy-state" transition:fade={{ duration: 250 }}>
        {copy_state}
      </div>
    </Popover>

    <Popover
      triggerEvents={["focus", "hover"]}
      referenceElement={referenceTag}
      placement="top"
      spaceAway={3}
      closeOnClickAway
    >
      <div class="copy-state" transition:fade={{ duration: 250 }}>
        {copy_state}
      </div>
    </Popover>
  </div>
</div>

<style lang="scss">
  @import "../vars.scss";

  .deposit {
    position: relative;
    background-color: $card-bg;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.2rem;
    padding: 0.8rem;
    width: fit-content;
    min-width: 250px;
  }

  .asset-icon {
    width: 1.8rem;
  }

  .clipboard-icon {
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary-lighter;
    }
  }

  .copy-state {
    background-color: $primary;
    color: white;
    padding: 0.25rem;
    border-radius: 0.2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  }

  .warning-text {
    width: 40vw !important;
    min-width: 300px !important;
  }
</style>
