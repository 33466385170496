<script>
	import { Router, Route } from "svelte-navigator";
	import { onMount } from "svelte";
	import Notifications from "svelte-notifications";

	import { isAuth } from "./store";
	import { logout, getUserData } from "./services/auth";
	import { getCurTypes, getCursList } from "./services/finance";
	import { StartWebSocket, webSocket } from "./socket";
	import getDeviceID from "./utils/getDeviceID";

	import PrivateRoute from "./components/privateRoute/PrivateRoute.svelte";

	import Nabvar from "./components/layout/Nabvar.svelte";
	import Dashboard from "./pages/Dashboard.svelte";
	import Signin from "./pages/auth/Signin.svelte";
	import Signup from "./pages/auth/Signup.svelte";
	import TwoFA from "./pages/auth/TwoFA.svelte";
	import Profile from "./pages/Profile.svelte";
	import History from "./pages/History.svelte";
	import Withdraw from "./pages/Withdraw.svelte";
	import CreateAccount from "./pages/CreateAccount.svelte";
	import Collateral from "./pages/Collateral.svelte";
	import Deposit from "./pages/Deposit.svelte";
	import VerifyAccount from "./pages/auth/VerifyAccount.svelte";
	import ResetPassword from "./pages/auth/ResetPassword.svelte";

	onMount(async () => {
		await getCursList();
		await getCurTypes();
		await StartWebSocket();

		if (!localStorage.deviceID) {
			const deviceID = await getDeviceID();
			localStorage.setItem("deviceID", deviceID);
		}

		if (localStorage.token) {
			isAuth.set(true);
			await getUserData();
		}
	});

	$: window.addEventListener("storage", async () => {
		if (!localStorage.token) {
			isAuth.set(false);
			await logout();
		}
	});

	$: window.addEventListener("offline", () => {
		console.log("The network connection has been lost.");
		webSocket.close();
	});

	$: window.addEventListener("online", async () => {
		console.log("The network connection is back");
		await StartWebSocket();
	});
</script>

<main class="bg-light">
	<Notifications>
		<Nabvar />
		<div class="container pages">
			<Router primary={false}>
				<Route path="/signin">
					<Signin />
				</Route>
				<Route path="/signup">
					<Signup />
				</Route>
				<Route path="/2fa">
					<TwoFA />
				</Route>
				<Route path="/verifyemail/:token" let:params>
					<VerifyAccount token={params.token} />
				</Route>
				<Route path="/resetpassword/:token" let:params>
					<ResetPassword token={params.token} />
				</Route>
				<PrivateRoute path="/">
					<Dashboard />
				</PrivateRoute>
				<PrivateRoute path="/profile">
					<Profile />
				</PrivateRoute>
				<PrivateRoute path="/history">
					<History />
				</PrivateRoute>
				<PrivateRoute path="/withdraw">
					<Withdraw />
				</PrivateRoute>
				<PrivateRoute path="/create-account">
					<CreateAccount />
				</PrivateRoute>
				<PrivateRoute path="/collateral">
					<Collateral />
				</PrivateRoute>
				<PrivateRoute path="/deposit">
					<Deposit />
				</PrivateRoute>
			</Router>
		</div>
	</Notifications>
</main>

<style lang="scss">
	@import "./vars.scss";

	main {
		text-align: center;
		padding: 0;
		margin: 0 auto;
		min-height: 100vh;
		background-image: url("../vendor/image/dashboard_bg.png");
		background-position: right;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.pages {
		padding-top: 100px;
		padding-bottom: 1.5rem;
		min-height: 100vh;
		@media screen and (max-width: 768px) {
			padding-bottom: 80px;
			padding-top: 1.5rem;
		}
	}
</style>
