import api from '../utils/api';
import { collaterals, adjust, isAdjustLoading, isCollateralLoading, adjustCollateralModal } from '../store';
import { logout } from './auth';
import { getLoan } from './finance';

// Get collateral
export const getCollaterals = async () => {
  try {
    isCollateralLoading.set(true);
    const res = await api.get('/collateral');

    if (res.result === 0) {
      console.log(res.data)
      collaterals.set(Object.entries(res.data));
      isCollateralLoading.set(false);
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
    }
  } catch (error) {
    console.log(error)
  }
};

// Get adjust
export const getAdjust = async (cur_id) => {
  try {
    isAdjustLoading.set(true);
    const res = await api.get(`/adjust/${cur_id}`);

    if (res.result === 0) {
      console.log(res.data);
      adjust.set(res.data);
      isAdjustLoading.set(false);
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();
      isAdjustLoading.set(false);
      return res;
    }
  } catch (error) {
    console.log(error)
  }
};

// Post adjust
export const postAdjust = async (cur_id, amount) => {
  try {
    const res = await api.post(`/adjust/${cur_id}`, JSON.stringify({ amount: amount }));

    if (res.result === 0) {
      console.log(res)
      adjustCollateralModal.set(false);
      await getCollaterals();
      await getLoan();
      return res.result;
    } else {
      console.log(res);
      if (res.result === 11 || res.result === 14 || res.result === 17 || res.result === 18 || res.result === 19 || res.result === 20)
        await logout();

      return res;
    }
  } catch (error) {
    console.log(error)
  }
};