<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-navigator";
  import { Icon } from "svelte-fontawesome";
  import {
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
  } from "@fortawesome/free-solid-svg-icons";

  import { accountVerify } from "../../services/auth";
  import isEmpty from "../../utils/validation/is-empty";
  import { isAccountVerifying } from "../../store";

  import DashboardSpinner from "../../components/loading/DashboardSpinner.svelte";

  export let token;

  let successText;
  let errorText;

  onMount(async () => {
    const ticket = {
      ticket: token,
    };

    const res = await accountVerify(ticket);

    if (res === 0) {
      successText = "Your account was successfully verified!";
    } else {
      if (!isEmpty(res.error_text)) errorText = res.error_text;
    }
  });
</script>

<div class="d-flex justify-content-center full-width">
  <div class="d-flex justify-content-center flex-column fit-content-width">
    {#if $isAccountVerifying}
      <div class="text-center">
        <DashboardSpinner />
        <p class="mb-0 cl-primary fs-5">Verifying</p>
      </div>
    {:else}
      {#if !isEmpty(successText)}
        <p class="fs-5 cl-primary">{successText}</p>
      {/if}
      {#if !isEmpty(errorText)}
        <p class="fs-5 text-danger">{errorText}</p>
      {/if}

      <div class="d-flex justify-content-between">
        <Link to="/signin">
          <div class="d-flex align-items-center link-item">
            <Icon icon={faArrowAltCircleLeft} />
            <span class="ms-2">Sign In</span>
          </div>
        </Link>
        <Link to="/signup">
          <div class="d-flex align-items-center link-item">
            <span class="me-2">Sign up</span>
            <Icon icon={faArrowAltCircleRight} />
          </div>
        </Link>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  @import "../../vars.scss";

  .link-item {
    color: $dark-blue;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    &:hover {
      color: $primary;
    }
  }
</style>
