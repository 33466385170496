<script>
  import Modal from "sv-bootstrap-modal";
  import Select from "svelte-select";
  import { getNotificationsContext } from "svelte-notifications";

  import {
    newCryptoModal,
    errors,
    crypto_currencies,
    cursList,
  } from "../../store";
  import isEmpty from "../../utils/validation/is-empty";

  import { createCryptoAccount } from "../../services/create-account";
  import cryptoAccountValidation from "../../utils/validation/cryptoAccountValidation";

  const { addNotification } = getNotificationsContext();

  let data = {
    name: "",
    addr: "",
    tag: "",
    two_fa: "",
  };
  let disabledTag = false;

  let currencyItems;
  let cur_id;

  let modalState;

  crypto_currencies.subscribe((v) => {
    currencyItems = v;
  });
  newCryptoModal.subscribe((v) => {
    modalState = v;
  });

  const onClose = () => {
    newCryptoModal.set(false);
  };

  const handleSelectCurrency = (e) => {
    cur_id = e.detail;

    errors.set({
      ...$errors,
      cur_id: "",
    });

    let currency = $cursList.find((item) => item.cur_id == e.detail.value);

    if (currency.tag) disabledTag = true;
    else disabledTag = false;
  };

  const handleClearCurrency = () => {
    cur_id = undefined;
    disabledTag = false;
  };

  const onInput = (e) => {
    errors.set({
      ...$errors,
      [e.target.name]: "",
    });
  };

  const createNewAccount = async () => {
    let temp = {
      cur_id: cur_id,
      name: data.name,
      addr: data.addr,
      tag: data.tag,
      two_fa: data.two_fa,
    };

    const { errorMsgs, isValid } = cryptoAccountValidation(temp);

    if (isValid) {
      const res = await createCryptoAccount(data, cur_id.value);

      if (res === 0) {
        addNotification({
          text: "Successfully created",
          position: "top-right",
          type: "success",
          removeAfter: 4000,
        });
      } else {
        if (!isEmpty(res.error_text))
          addNotification({
            text: res.error_text,
            position: "top-right",
            type: "danger",
            removeAfter: 4000,
          });
      }
    } else errors.set(errorMsgs);
  };

  $: if (!modalState) {
    errors.set({});
    data = {
      name: "",
      addr: "",
      tag: "",
      two_fa: "",
    };
    cur_id = undefined;
    disabledTag = false;
  }
  $: if (currencyItems.length === 1 && modalState) cur_id = currencyItems[0];
</script>

<Modal bind:open={$newCryptoModal} dialogClasses="modal-dialog-centered">
  <div class="modal-header">
    <h5 class="modal-title cl-primary">New Crypto Address</h5>
    <button type="button" class="btn-close" on:click={onClose} />
  </div>

  <div class="modal-body text-start">
    <form on:submit|preventDefault={createNewAccount} novalidate>
      <div class="form-group">
        <label for="currency" class="cl-primary mb-2">Currency</label>
        <Select
          items={currencyItems}
          value={cur_id}
          id="currency"
          containerClasses="primary-select"
          on:select={handleSelectCurrency}
          on:clear={handleClearCurrency}
        />
        {#if $errors.cur_id}
          <div class="text-danger">{$errors.cur_id}</div>
        {/if}
      </div>

      <div class="form-group mt-3">
        <label for="name" class="cl-primary">Name</label>
        <input
          type="text"
          class={`form-control mt-2 primary-input ${
            $errors.name ? "is-invalid" : null
          }`}
          name="name"
          id="name"
          bind:value={data.name}
          on:input={onInput}
        />
        {#if $errors.name}
          <div class="text-danger">{$errors.name}</div>
        {/if}
      </div>

      <div class="form-group mt-3">
        <label for="addr" class="cl-primary">Address</label>
        <input
          type="text"
          class={`form-control mt-2 primary-input ${
            $errors.addr ? "is-invalid" : null
          }`}
          name="addr"
          id="addr"
          bind:value={data.addr}
          on:input={onInput}
        />
        {#if $errors.addr}
          <div class="text-danger">{$errors.addr}</div>
        {/if}
      </div>

      {#if disabledTag}
        <div class="form-group mt-3">
          <label for="tag" class="cl-primary">Tag</label>
          <input
            type="text"
            class={`form-control mt-2 primary-input ${
              $errors.tag ? "is-invalid" : null
            }`}
            name="tag"
            id="tag"
            bind:value={data.tag}
            on:input={onInput}
          />
          {#if $errors.tag}
            <div class="text-danger">{$errors.tag}</div>
          {/if}
        </div>
      {/if}

      <div class="form-group mt-3">
        <label for="two_fa" class="cl-primary">2FA Code</label>
        <input
          type="number"
          class={`form-control mt-2 primary-input ${
            $errors.two_fa ? "is-invalid" : null
          }`}
          name="two_fa"
          id="two_fa"
          bind:value={data.two_fa}
          on:input={onInput}
        />
        {#if $errors.two_fa}
          <div class="text-danger">{$errors.two_fa}</div>
        {/if}
      </div>

      <div class="d-flex justify-content-end mt-3">
        <button type="submit" class="btn-main">Save Address</button>
      </div>
    </form>
  </div>
</Modal>

<style lang="scss">
</style>
